import { FC, PropsWithChildren, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { format, parse, addDays } from "date-fns";
import {
  Invoice,
  InvoicePayStatus,
} from "models/paymentDocument/invoice/invoice.interface";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import {
  AccentColor,
  TemplateLayout,
} from "hooks/templates/models/template.interface";
import { InvoiceFormValues } from "./InvoiceForm";

interface Props {
  defaultValues?: Invoice;
  schema:
    | HTMLValidationSchema<InvoiceFormValues>
    | HTMLValidationSchemaGenerator<InvoiceFormValues>;
}

export const getInvoiceFormDefaultValues = (
  defaultValues?: Invoice
): Partial<InvoiceFormValues> => {
  const invoicePayStatus = defaultValues?.invoicePayStatus;

  return {
    ...defaultValues,
    reference: defaultValues?.reference
      ? String(defaultValues?.reference)
      : undefined,
    customerId: defaultValues?.customerId ?? undefined,
    issueDate: defaultValues?.issueDate
      ? format(
          parse(defaultValues?.issueDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )
      : format(new Date(), "yyyy-MM-dd"),
    dueDate: defaultValues?.dueDate
      ? format(
          parse(defaultValues?.dueDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )
      : format(addDays(new Date(), 30), "yyyy-MM-dd"),
    documentLines: defaultValues?.documentLines ?? [
      {
        quantity: 1,
        vatPercentage: 21,
        articleNumber: "",
        description: "",
        note: "",
      },
    ],
    template: {
      id: defaultValues?.template?.id ?? "",
      accentColor: defaultValues?.template?.accentColor ?? AccentColor.Gray,
      layout: defaultValues?.template?.layout ?? TemplateLayout.Template1,
    },
    ...(invoicePayStatus
      ? {
          invoicePayStatus:
            invoicePayStatus === InvoicePayStatus.OverDue
              ? InvoicePayStatus.Due
              : invoicePayStatus,
        }
      : {
          invoicePayStatus: InvoicePayStatus.Due,
        }),
    invoicePayType: defaultValues?.invoicePayType ?? undefined,
    // freeCommunication: defaultValues?.freeCommunication ?? undefined,
    // structuredCommunication:
    //   defaultValues?.structuredCommunication ?? undefined,
    invoicePayStatus: defaultValues?.payDate
      ? InvoicePayStatus.Paid
      : InvoicePayStatus.Due,
    payDate: defaultValues?.payDate
      ? format(
          parse(defaultValues?.payDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )
      : format(new Date(), "yyyy-MM-dd"),
  };
};

export const InvoiceFormProvider: FC<PropsWithChildren<Props>> = function ({
  defaultValues,
  schema,
  children,
}) {
  const methods = useForm<InvoiceFormValues>({
    resolver: HTMLValidationSchemaResolver(schema),
    mode: "onBlur",
    defaultValues: getInvoiceFormDefaultValues(defaultValues),
  });

  useEffect(() => {
    if (defaultValues?.id !== methods.getValues("id")) {
      methods.reset(getInvoiceFormDefaultValues(defaultValues));
    }
  }, [defaultValues, methods]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};
