import { useMemo, useCallback, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiCreditCard } from "react-icons/hi";
import { Spinner } from "flowbite-react";

import { useRoutes } from "domains";

import { EditModal } from "components/modals/EditModal";
import { NoHeaderModal } from "components/modals/NoHeaderModal";

import { Breadcrumb, PageHeader } from "components/PageHeader";
import { ConnectableBanks } from "./components/ConnectableBanks";
import { Connections } from "./components/Connections";
import { TermsModal } from "./components/TermsModal";
import { useGetUserBanks } from "./queries/usetGetUserBanks";
import { useGetTerms } from "./queries/useGetTerms";
import { useCreateBankUser } from "./mutations/useCreateBankUser";
import { useUpdateBankUser } from "./mutations/useUpdateBankUser";
import IbanForm, { IbanFormValues } from "./components/IbanForm";
import { FinalizeConnection } from "./components/FinalizeConnection";
import { InitiateConnection } from "./components/InitiateConnection";
import { RevokeConnection } from "./components/RevokeConnection";

function BankAccountsPage() {
  const { t, i18n } = useTranslation();
  const { routes, goToBankAccounts } = useRoutes();
  const { action } = useParams();
  const [searchParams] = useSearchParams();
  const params = searchParams?.toString();

  const connectorId = searchParams.get("connectorId");

  const breadcrumbs = useMemo(() => {
    const crumbs: Breadcrumb[] = [
      {
        href: routes.bankAccounts,
        label: t("sidebar.title.banking") as string,
        icon: HiCreditCard,
      },
    ];
    return crumbs;
  }, [routes, t]);

  const { data, error, isLoading } = useGetUserBanks();

  const { data: termsData } = useGetTerms({
    language: "EN" || i18n.language,
  });

  const createBankUser = useCreateBankUser();
  const updateBankUser = useUpdateBankUser();

  const handleApprove = useCallback(() => {
    if (termsData?.version) {
      if (error?.cause?.status === 404) {
        // do post
        createBankUser.mutate({ tc: termsData.version });
      } else {
        // do put
        updateBankUser.mutate({ tc: termsData.version });
      }
    }
  }, [error, termsData, updateBankUser, createBankUser]);

  const handleGoToInitiate = useCallback(
    (values: IbanFormValues) =>
      goToBankAccounts(
        `/initiate?connectorId=${connectorId}&iban=${values.iban}`
      ),
    [goToBankAccounts, connectorId]
  );

  useEffect(() => {
    if (action === "callback") {
      setTimeout(() => goToBankAccounts(`/finalize?${params}`), 1000);
    }
  }, [goToBankAccounts, action, params]);

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.bankAccounts")}
        breadcrumbs={breadcrumbs}
      />
      <div className="p-4 flex gap-4 flex-col">
        {isLoading ? (
          <div className="mx-auto p-5">
            <Spinner size="lg" />
          </div>
        ) : (
          <>
            <Connections connections={data?.bankAccounts} />
            <ConnectableBanks
              noUserYet={error?.cause?.status === 404}
              notAcceptedLatestTerms={error?.cause?.status === 400}
            />
            {action === "bank-user" && (
              <TermsModal
                show
                isApprving={
                  createBankUser.isLoading || updateBankUser.isLoading
                }
                termsData={termsData}
                onApprove={handleApprove}
                onClose={goToBankAccounts}
                size="md"
              />
            )}
            {action === "enter-iban" && (
              <EditModal
                action="create"
                isError={false}
                page="iban"
                onClose={goToBankAccounts}
                show
              >
                <IbanForm
                  isLoading={false}
                  onSubmit={
                    handleGoToInitiate as (data: IbanFormValues) => void
                  }
                  schema={{
                    iban: { required: true },
                  }}
                />
              </EditModal>
            )}
            <NoHeaderModal
              show={action === "initiate"}
              onClose={goToBankAccounts}
              size="md"
            >
              <InitiateConnection show={action === "initiate"} />
            </NoHeaderModal>
            {!isLoading && data?.bankAccounts && (
              <NoHeaderModal
                show={action === "finalize"}
                onClose={goToBankAccounts}
                size="md"
              >
                <FinalizeConnection show={action === "finalize"} />
              </NoHeaderModal>
            )}
            {action === "revoke" && (
              <NoHeaderModal show onClose={goToBankAccounts} size="md">
                <RevokeConnection />
              </NoHeaderModal>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default BankAccountsPage;
