import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { Accordion, Label } from "flowbite-react";
import { useTranslation } from "react-i18next";
import { DevTool } from "@hookform/devtools";

import { ALLLOWED_CURRENCIES, Currency } from "helpers/to-currency";
import { LoadingButton } from "components/LoadingButton";
import { Select } from "components/input/Select";
import { TextInput } from "components/input/TextInput";
// import { H3 } from "components/headers/H3";
// import { AccentColorRadio } from "components/invoice/form/AccentColorRadio";
import { CustomerTypeAhead } from "components/invoice/form/CustomerTypeAhead";
// import { MaskedTextInput } from "components/input/MaskedTextInput";
// import { PersonalTemplateRadio } from "components/invoice/form/PersonalTemplateRadio";
import { ProductsFieldset } from "components/invoice/form/ProductsFieldset";
import { RadioButtonGroup } from "components/input/radioButton/RadioButtonGroup";
// import { TemplateRadio } from "components/invoice/form/TemplateRadio";
// import { TextArea } from "components/input/TextArea";
import { useInvoiceFormValues } from "components/invoice/hooks/useInvoiceFormValues";
import { NoHeaderModal } from "components/modals/NoHeaderModal";
import { Button } from "components/Button";

import { useEvent } from "hooks/useEvent";
import { Customer } from "hooks/customers/models/customer.interface";
import { Product } from "hooks/products/models/product.interface";
import { SalesInvoice } from "hooks/salesInvoices/models/salesInvoice.interface";
import { CustomerCreateEvent } from "hooks/customers/useCustomersUseCases";

import {
  // AccentColor,
  Template,
  // TemplateLayout,
} from "hooks/templates/models/template.interface";
import {
  CreateInvoiceInput,
  UpdateInvoiceInput,
} from "models/paymentDocument/invoice/invoiceInput.interface";
import {
  Invoice,
  InvoiceStatus,
  InvoicePayStatus,
} from "models/paymentDocument/invoice/invoice.interface";

import { InvoicePayType } from "models/paymentDocument/paymentDocument.interface";
import { InvoiceFormCreateModals } from "./InvoiceFormCreateModals";
import { InvoiceTimeline } from "./InvoiceTimeline";

export type InvoiceFormValues = UpdateInvoiceInput | CreateInvoiceInput;

export interface InvoiceFormProps {
  customers: Customer[];
  products: Product[];
  templates: Template[];
  isLoading: boolean;
  onSubmit: (input: InvoiceFormValues) => void;
  onChange?: (invoice: Partial<Omit<Invoice, "id" | "update">>) => void;
  invoice?: SalesInvoice;
}

// const structuredPaymentCommunicationMask = [
//   /[0-9]/,
//   /[0-9]/,
//   /[0-9]/,
//   "/",
//   /[0-9]/,
//   /[0-9]/,
//   /[0-9]/,
//   /[0-9]/,
//   "/",
//   /[0-9]/,
//   /[0-9]/,
//   /[0-9]/,
//   /[0-9]/,
//   /[0-9]/,
// ];

export const InvoiceForm: FC<InvoiceFormProps> = function ({
  invoice,
  customers,
  products,
  // templates,
  isLoading,
  onSubmit,
}) {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const {
    setValue,
    formState,
    handleSubmit,
    register,
    control,
    resetField,
    trigger,
  } = useFormContext<InvoiceFormValues>();

  const {
    // issueDate,
    // dueDate,
    // total,
    // vat,
    selectedCustomer,
    // selectedProducts,
    // selectedTemplate,
    invoicePayStatus,
    // invoicePayType,
  } = useInvoiceFormValues({ customers, products });

  // const [paymentCommunicationType, setPaymentMessageType] = useState<
  //   "free" | "structured"
  // >(invoice?.structuredCommunication ? "structured" : "free");

  // Prevent edit of some fields (products, invoice dates, customer,...) when the invoice is already created

  const mustConfirm = invoice?.invoiceStatus === InvoiceStatus.SendToCustomer;

  const isSendToAccountant =
    invoice?.invoiceStatus === InvoiceStatus.SendToAccountant;

  const allowEdit = !isSendToAccountant;

  const productFieldArrayMethods = useFieldArray<InvoiceFormValues>({
    name: "documentLines",
  });

  // const onPersonalTemplatePicked = useCallback(
  //   (selectedId: string): void => {
  //     const template = templates.find(({ id }) => id === selectedId);

  //     if (template) {
  //       setValue("template.id", template.id);
  //       setValue("template.accentColor", template.accentColor);
  //       setValue("template.layout", template.layout);
  //     }
  //   },
  //   [setValue, templates]
  // );

  // const onDefaultTemplatePicked = useCallback((): void => {
  //   setValue("template.id", undefined);
  //   setValue("template.accentColor", AccentColor.Gray);
  //   setValue("template.layout", TemplateLayout.Template1);
  // }, [setValue]);

  // const onAccentColorPicked = useCallback((): void => {
  //   setValue("template.id", undefined);
  // }, [setValue]);

  // const preview = useMemo(() => {
  //   return {
  //     issueDate,
  //     dueDate,
  //     products: selectedProducts,
  //     invoiceNumber: invoice?.invoiceNumber,
  //     template: selectedTemplate,
  //     to: selectedCustomer,
  //     totalInclusiveVat: total,
  //     vatPrice: vat,
  //   };
  // }, [
  //   issueDate,
  //   dueDate,
  //   invoice,
  //   selectedCustomer,
  //   selectedProducts,
  //   selectedTemplate,
  //   total,
  //   vat,
  // ]);

  const hasSpecificationsErrors = useMemo(
    () =>
      formState.errors.customerId ||
      formState.errors.issueDate ||
      formState.errors.dueDate ||
      formState.errors.documentLines,
    [formState]
  );

  // const handlePaymentMessageTypeInputChange = useCallback(
  //   (value: string) => {
  //     setPaymentMessageType(value as "free" | "structured");
  //     resetField(
  //       value === "free" ? "structuredCommunication" : "freeCommunication"
  //     );
  //   },
  //   [resetField]
  // );

  // Reset form fields to default values when payment status changes
  useEffect(() => {
    if (invoicePayStatus === InvoicePayStatus.Due) {
      resetField("payDate");
      resetField("invoicePayType");
      resetField("freeCommunication");
      resetField("structuredCommunication");
    }
  }, [resetField, invoicePayStatus]);

  const onCustomerCreated = useCallback(
    ({ customerId }: CustomerCreateEvent) => {
      setValue("customerId", customerId);
    },
    [setValue]
  );

  const handleShowConfirmation = useCallback(async () => {
    const noErrors = await trigger();
    if (noErrors) setShowConfirmation(true);
  }, [setShowConfirmation, trigger]);

  const handleHideConfirmation = useCallback(
    () => setShowConfirmation(false),
    [setShowConfirmation]
  );

  // productFieldArrayMethods.fields[0]?.vatPercentage
  const initialVatPercentage = 21;

  useEvent("customerCreatedFromInvoice", onCustomerCreated);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="pb-8">
        <Accordion flush alwaysOpen>
          <Accordion.Panel>
            <Accordion.Title
              theme={{
                heading: hasSpecificationsErrors
                  ? "text-red-600 text-2xl font-semibold"
                  : "text-2xl font-semibold dark:text-white",
              }}
            >
              {t("invoiceForm.title.specifications")}
            </Accordion.Title>
            <Accordion.Content>
              {/* Customer type ahead */}
              {allowEdit && (
                <div className="mb-4 flex flex-col flex-1 gap-2">
                  <Label htmlFor="to">{t("invoiceForm.input.to")}</Label>
                  <CustomerTypeAhead
                    customerKey="customerId"
                    id="customerId"
                    selectedCompany={selectedCustomer?.name}
                    placeholder={t("invoiceForm.placeholder.to") as string}
                  />
                </div>
              )}
              {/* Invoice nr & currency */}
              {allowEdit && (
                <div className="flex gap-4">
                  <div className="mb-4 flex flex-col flex-1 gap-2">
                    <Label htmlFor="reference">
                      {t("invoiceForm.input.reference")}
                    </Label>
                    <TextInput
                      {...register("reference")}
                      type="text"
                      id="reference"
                      errorMessage={formState.errors.reference?.message}
                      placeholder={
                        t("invoiceForm.placeholder.reference") as string
                      }
                    />
                  </div>
                  <div className="mb-4 flex flex-col flex-1 gap-2">
                    <Label htmlFor="currency">
                      {t("invoiceForm.input.currency")}
                    </Label>
                    <Select
                      {...register("currency")}
                      id="currency"
                      errorMessage={formState.errors?.currency?.message}
                      placeholder={
                        t("invoiceForm.placeholder.currency") as string
                      }
                    >
                      {Object.values(Currency)
                        .filter((v) =>
                          ALLLOWED_CURRENCIES.find((cr) => cr === v)
                        )
                        .map((value) => (
                          <option key={value} value={value}>
                            {t(`currency.${value}`)}
                          </option>
                        ))}
                    </Select>
                  </div>
                </div>
              )}
              {/* Billed on & Expires on date */}
              {allowEdit && (
                <div className="flex gap-4">
                  <div className="mb-4 flex flex-col flex-1 gap-2">
                    <Label htmlFor="issueDate">
                      {t("invoiceForm.input.issueDate.label")}
                    </Label>
                    <TextInput
                      {...register("issueDate")}
                      type="date"
                      placeholder={
                        t("invoiceForm.placeholder.issueDate") as string
                      }
                      id="issueDate"
                      errorMessage={formState.errors.issueDate?.message}
                    />
                  </div>
                  <div className="mb-4 flex flex-col flex-1 gap-2">
                    <Label htmlFor="dueDate">
                      {t("invoiceForm.input.dueDate.label")}
                    </Label>
                    <TextInput
                      {...register("dueDate")}
                      type="date"
                      placeholder={
                        t("invoiceForm.placeholder.dueDate") as string
                      }
                      id="to"
                      errorMessage={formState.errors.dueDate?.message}
                    />
                  </div>
                </div>
              )}

              {/* Payment info */}
              <div className="mb-4 flex flex-col flex-1 gap-2">
                <Label htmlFor="invoicePayStatus">
                  {t("invoiceForm.input.invoicePayStatus.label")}
                </Label>
                <Controller
                  name="invoicePayStatus"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <RadioButtonGroup
                      name="invoicePayStatus"
                      options={[
                        {
                          label: t("invoiceForm.input.invoicePayStatus.paid"),
                          value: InvoicePayStatus.Paid,
                        },
                        {
                          label: t("invoiceForm.input.invoicePayStatus.unpaid"),
                          value: InvoicePayStatus.Due,
                        },
                      ]}
                      selectedValue={value as string}
                      onChange={onChange}
                    />
                  )}
                />
              </div>

              {invoicePayStatus === InvoicePayStatus.Paid && (
                <>
                  {/* Payment date: default vandaag */}
                  <div className="mb-4 flex flex-col flex-1 gap-2">
                    <Label htmlFor="payDate">
                      {t("invoiceForm.input.payDate")}
                    </Label>
                    <TextInput
                      {...register("payDate")}
                      type="date"
                      placeholder={
                        t("invoiceForm.placeholder.payDate") as string
                      }
                      id="payDate"
                      errorMessage={formState.errors.payDate?.message}
                    />
                  </div>

                  {/* Payment method dropdown */}
                  <div className="mb-4 flex flex-col flex-1 gap-2">
                    <Label htmlFor="invoicePayType">
                      {t("invoiceForm.input.invoicePayType.label")}
                    </Label>
                    <Select
                      {...register("invoicePayType")}
                      id="invoicePayType"
                      errorMessage={formState.errors.invoicePayType?.message}
                    >
                      <option value="">
                        {t("invoiceForm.placeholder.invoicePayType")}
                      </option>
                      <option value={InvoicePayType.Cash}>
                        {t("invoiceForm.input.invoicePayType.cash")}
                      </option>
                      <option value={InvoicePayType.BankTransfer}>
                        {t("invoiceForm.input.invoicePayType.bankTransfer")}
                      </option>
                      <option value={InvoicePayType.CreditCard}>
                        {t("invoiceForm.input.invoicePayType.creditCard")}
                      </option>
                    </Select>
                  </div>

                  {/* Structured message / Free message  */}
                  {/* {invoicePayType === InvoicePayType.BankTransfer && (
                    <div className="mb-4 flex flex-col flex-1 gap-2">
                      <Label htmlFor="paymentCommunicationType">
                        {t("invoiceForm.input.paymentCommunicationType.label")}
                      </Label>
                      <RadioButtonGroup
                        name="paymentCommunicationType"
                        options={[
                          {
                            label: t(
                              "invoiceForm.input.paymentCommunicationType.free"
                            ),
                            value: "free",
                          },
                          {
                            label: t(
                              "invoiceForm.input.paymentCommunicationType.structured"
                            ),
                            value: "structured",
                          },
                        ]}
                        selectedValue={paymentCommunicationType}
                        onChange={handlePaymentMessageTypeInputChange}
                      />
                    </div>
                  )}

                  {invoicePayType === InvoicePayType.BankTransfer &&
                    paymentCommunicationType === "free" && (
                      <div className="mb-4 flex flex-col flex-1 gap-2">
                        <Label htmlFor="freeCommunication">
                          {t("invoiceForm.input.freeCommunication")}
                        </Label>
                        <TextArea
                          {...register("freeCommunication")}
                          placeholder={
                            t(
                              "invoiceForm.placeholder.freeCommunication"
                            ) as string
                          }
                          id="freeCommunication"
                          maxLength={255}
                          errorMessage={
                            formState.errors.freeCommunication?.message
                          }
                        />
                      </div>
                    )}

                  {invoicePayType === InvoicePayType.BankTransfer &&
                    paymentCommunicationType === "structured" && (
                      <div className="mb-4 flex flex-col flex-1 gap-2">
                        <Label htmlFor="structuredCommunication">
                          {t("invoiceForm.input.structuredCommunication.label")}
                        </Label>
                        <Controller
                          name="structuredCommunication"
                          render={({ field: { onChange, ref, ...rest } }) => (
                            <MaskedTextInput
                              {...rest}
                              mask={structuredPaymentCommunicationMask}
                              id="structuredCommunication"
                              guide
                              showMask
                              ref={ref}
                              onChange={onChange}
                              errorMessage={
                                formState.errors.structuredCommunication
                                  ?.message
                              }
                            />
                          )}
                        />
                      </div>
                    )} */}
                </>
              )}

              {/* Products */}
              {allowEdit && (
                <ProductsFieldset
                  {...productFieldArrayMethods}
                  initialVatPercentage={initialVatPercentage}
                />
              )}
              <hr className="h-px mt-10 mb-6 bg-gray-200 border-0 dark:bg-gray-700" />
              <div className="mb-4 flex flex-col flex-1 gap-2">
                <Label htmlFor="extraInformation">
                  {t("invoiceForm.input.extraInformation")}
                </Label>
                <TextInput
                  {...register("extraInformation")}
                  type="text"
                  id="extraInformation"
                  placeholder={
                    t("invoiceForm.placeholder.extraInformation") as string
                  }
                />
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          {invoice && invoice.id ? (
            <Accordion.Panel>
              <Accordion.Title className="text-2xl font-semibold dark:text-white">
                {t("invoiceForm.title.timeline")}
              </Accordion.Title>
              <Accordion.Content>
                <div className="flex w-full">
                  <InvoiceTimeline
                    issueDate={invoice?.issueDate}
                    sentDate={invoice?.sentDate}
                    payDate={invoice?.payDate}
                    reminders={invoice?.reminders}
                  />
                </div>
              </Accordion.Content>
            </Accordion.Panel>
          ) : (
            <div />
          )}
          {/* <>
            {allowEdit ? (
              <Accordion.Panel>
                <Accordion.Title
                  theme={{
                    heading: formState.errors.template
                      ? "text-red-600 text-2xl font-semibold"
                      : "text-2xl font-semibold dark:text-white",
                  }}
                >
                  {t("invoiceForm.title.layout")}
                </Accordion.Title>
                <Accordion.Content>
                  <H3>{t("invoiceForm.input.color")}</H3>
                  <div className="flex gap-4">
                    {Object.values(AccentColor).map((color) => (
                      <AccentColorRadio
                        key={color}
                        color={color}
                        onChange={onAccentColorPicked}
                        disabled={!allowEdit}
                      />
                    ))}
                  </div>
                  <H3 className="mt-8">{t("invoiceForm.input.template")}</H3>
                  <div className="flex gap-4 flex-wrap">
                    {templates.map((template) => (
                      <PersonalTemplateRadio
                        key={template.id}
                        preview={preview}
                        changePersonalTemplate={onPersonalTemplatePicked}
                        template={template}
                        disabled={!allowEdit}
                      />
                    ))}
                  </div>
                  <H3 className="mt-8">{t("invoiceForm.input.layout")}</H3>
                  <div className="flex gap-4 flex-wrap">
                    {Object.values(TemplateLayout).map((layout) => (
                      <TemplateRadio
                        key={layout}
                        onChange={onDefaultTemplatePicked}
                        layout={layout}
                        preview={preview}
                        disabled={!allowEdit}
                      />
                    ))}
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            ) : (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <></>
            )}
          </> */}
        </Accordion>

        {mustConfirm ? (
          <div className="p-4 flex justify-end">
            <LoadingButton
              disabled={productFieldArrayMethods.fields?.length <= 0}
              onClick={handleShowConfirmation}
            >
              {t("invoiceForm.button.save")}
            </LoadingButton>
          </div>
        ) : (
          <div className="p-4 flex justify-end">
            <LoadingButton
              disabled={productFieldArrayMethods.fields?.length <= 0}
              loading={isLoading}
              type="submit"
            >
              {t("invoiceForm.button.save")}
            </LoadingButton>
          </div>
        )}
      </form>
      {showConfirmation && (
        <NoHeaderModal show onClose={handleHideConfirmation} size="md">
          <p className="text-base text-center leading-relaxed text-gray-400 dark:text-gray-400">
            {t("invoiceForm.confirm.info")}
          </p>
          <div className="flex gap-4 justify-center">
            <LoadingButton loading={isLoading} onClick={handleSubmit(onSubmit)}>
              {t("invoiceForm.confirm.yes")}
            </LoadingButton>
            <Button color="gray" onClick={handleHideConfirmation}>
              {t("invoiceForm.confirm.no")}
            </Button>
          </div>
        </NoHeaderModal>
      )}
      <DevTool control={control} /> {/* set up the dev tool */}
      <InvoiceFormCreateModals {...productFieldArrayMethods} />
    </>
  );
};
