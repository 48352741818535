import { StrictMode, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Flowbite, Alert } from "flowbite-react";
import { initializeApp } from "firebase/app";
import { PublicClientApplication } from "@azure/msal-browser";
import isAfter from "date-fns/isAfter";

import { AppLoader } from "components/AppLoader";
import { CompanyErrorPage } from "components/errors/CompanyErrorPage";
import { authErrorCount } from "components/msal/config";
import { setTheme } from "helpers/set-theme";
import { useFile, useNotifications } from "domains";
import { SidebarProvider } from "context/SidebarContext";
import { useNotificationsEffects } from "hooks/notifications/useNotificationsUseCases";
import { FILE_QUERY, useGetFile } from "hooks/file/queries/useGetFile";
import { useGetSubscription } from "hooks/subscription/useGetSubscription";
import Portal from "hooks/notifications/portals/NotificationPortal";
import Content from "components/Routes";
import { LOCAL_STORAGE_LANGUAGE } from "components/sidebar/LanguageSelector";
import { JournalContextProvider } from "context/JournalContext";

import MsalAuth from "components/msal/MsalAuth";
import { createMsalInstance, msalConfig } from "helpers/msal";
import { firebaseConfig } from "./firebase.config";
import theme from "./flowbite-theme";

import "./index.css";
import "./i18n/i18n";

const container = document.getElementById("root");

if (!container) {
  throw new Error("React root element doesn't exist!");
}

const root = createRoot(container);

initializeApp(firebaseConfig);

// TODO: environment variable for mocking
// worker.start({
// onUnhandledRequest: "bypass",
// });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const pca: PublicClientApplication = createMsalInstance(msalConfig);

localStorage.removeItem(authErrorCount);

function App() {
  const { i18n } = useTranslation();

  const authErrors = Number(localStorage.getItem(authErrorCount));

  const {
    theme,
    isLoading: isLoadingFile,
    defaultLanguage,
  } = useFile(useGetFile(authErrors));

  const {
    data: subscription,
    isError,
    isLoading: isLoadingSubscription,
  } = useGetSubscription();

  const isValidSubscription = useMemo(() => {
    if (isError) return false;
    if (!subscription?.endDate) return false;

    return isAfter(new Date(subscription?.endDate), new Date());
  }, [subscription, isError]);

  const notifications = useNotifications();
  useNotificationsEffects(notifications);

  useEffect(() => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey[0] !== FILE_QUERY,
    });
  }, [i18n.language]);

  useEffect(() => {
    if (theme) {
      setTheme(theme);
    }
  }, [theme]);

  useEffect(() => {
    const language = localStorage.getItem(LOCAL_STORAGE_LANGUAGE);
    if (!language) {
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem(LOCAL_STORAGE_LANGUAGE, defaultLanguage);
    }
  }, [defaultLanguage, i18n]);

  if (authErrors >= 5) {
    return <CompanyErrorPage />;
  }

  if (isLoadingFile || isLoadingSubscription) {
    return <AppLoader />;
  }

  return (
    <>
      <Content isValidSubscription={isValidSubscription} />
      <Portal>
        {notifications.notifications?.length > 0 && (
          <>
            {notifications.notifications.map((notification, i) => (
              <div
                key={String(`${i}_${notification.message}`)}
                className="absolute right-8 left-8 sm:left-auto max-w-full sm:max-w-xs z-9999999999"
                style={{ marginTop: `${30 + (i + 1) * 55}px` }}
              >
                <Alert
                  onDismiss={() => notifications.close(i)}
                  color={notification.type === "error" ? "red" : "green"}
                >
                  <div className="w-full pr-1 z-999">
                    {notification.message}
                  </div>
                </Alert>
              </div>
            ))}
          </>
        )}
      </Portal>
    </>
  );
}

root.render(
  <StrictMode>
    <BrowserRouter>
      <MsalAuth pca={pca}>
        <SidebarProvider>
          <QueryClientProvider client={queryClient}>
            <JournalContextProvider>
              <Flowbite theme={{ theme }}>
                <App />
              </Flowbite>
            </JournalContextProvider>
          </QueryClientProvider>
        </SidebarProvider>
      </MsalAuth>
    </BrowserRouter>
  </StrictMode>
);
