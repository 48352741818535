import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { Label } from "flowbite-react";
import { DevTool } from "@hookform/devtools";

import { LoadingButton } from "components/LoadingButton";
import { TextInput } from "components/input/TextInput";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
import { useEvent } from "hooks/useEvent";
import {
  CreateProductInput,
  Product,
  ProductDescription,
  ProductDTO,
  UpdateProductInput,
} from "hooks/products/models/product.interface";
import { ProductGroup } from "hooks/productGroups/models/productGroup.interface";
import { LanguageTabBar } from "components/LanguageTabBar";
import { supportedProductLanguages } from "domains";
import { getEmptyProductDescriptions } from "hooks/products/ProductMapper";
// import { addMissingDescriptions } from "hooks/products/helpers/add-missing-descriptions";

import { ProductGroupTypeAhead } from "./ProductGroupTypeAhead";

export type ProductFormValues = CreateProductInput | UpdateProductInput;

interface ProductFormProps {
  product?: Partial<Product>;
  productGroups: ProductGroup[];
  isLoading: boolean;
  onSubmit: (input: ProductFormValues) => void;
  schema:
    | HTMLValidationSchema<ProductFormValues>
    | HTMLValidationSchemaGenerator<ProductFormValues>;
}

export const addMissingDescriptions = (
  product: ProductDTO | Partial<ProductDTO> | undefined,
  supportedLanguages: string[]
): ProductDescription[] => {
  const existingLanguageCodes = (product?.descriptions || [])
    .map((desc) => desc.code.toLowerCase())
    .sort();

  const missingLanguageCodes = supportedLanguages.filter(
    (lang) => !existingLanguageCodes.includes(lang.toLowerCase())
  );

  const newDescriptions: ProductDescription[] = missingLanguageCodes
    .sort()
    .map((lang) => ({
      code: lang,
      description: "",
    }));

  return [
    ...(product?.descriptions?.sort((a, b) => a.code.localeCompare(b.code)) ??
      []),
    ...newDescriptions,
  ];
};

const ProductForm: FC<ProductFormProps> = function ({
  product,
  productGroups,
  isLoading,
  onSubmit,
  schema,
}) {
  const { t } = useTranslation();
  const methods = useForm<ProductFormValues>({
    defaultValues: {
      ...product,
      vatPercentage: product?.vatPercentage || 21,
      descriptions: !product?.descriptions
        ? getEmptyProductDescriptions(supportedProductLanguages)
        : addMissingDescriptions(
            product as ProductDTO,
            supportedProductLanguages
          ),
    },
    mode: "onBlur",
    resolver: HTMLValidationSchemaResolver(schema),
  });

  const articleGroupId = useWatch<ProductFormValues>({
    control: methods.control,
    name: "articleGroupId",
  });

  const selectedProductGroup = useMemo(() => {
    return productGroups.find(({ id }) => id === articleGroupId)?.name;
  }, [productGroups, articleGroupId]);

  const supportedLanguages = useMemo(() => {
    return addMissingDescriptions(
      product as ProductDTO,
      supportedProductLanguages
    ).map((description) => description.code);
  }, [product]);

  const onProductGroupCreated = useCallback(
    (articleGroupId: string) =>
      methods.setValue("articleGroupId", articleGroupId),
    [methods]
  );

  useEvent("productGroupCreatedFromProduct", onProductGroupCreated);

  return (
    <FormProvider {...methods}>
      <form className="!m-0" onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4 p-6">
          <div className="flex gap-4">
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="reference"
                  value={t("productForm.label.reference") ?? ""}
                />
              </div>
              <TextInput
                id="reference"
                type="text"
                {...methods.register("reference")}
                errorMessage={methods.formState.errors.reference?.message}
              />
            </div>
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="articleGroupId"
                  value={t("productForm.label.productGroup") ?? ""}
                />
              </div>
              <ProductGroupTypeAhead
                id="articleGroupId"
                selectedProductGroup={selectedProductGroup}
              />
            </div>
          </div>
          <div className="flex gap-4">
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="amountExclusiveVat"
                  value={t("productForm.label.price") ?? ""}
                />
              </div>
              <TextInput
                id="amountExclusiveVat"
                addon="€"
                type="number"
                step="0.01"
                {...methods.register("amountExclusiveVat", {
                  valueAsNumber: true,
                })}
                errorMessage={
                  methods.formState.errors.amountExclusiveVat?.message
                }
              />
            </div>
            <div className="w-1/2">
              <div className="mb-2 block">
                <Label
                  htmlFor="vatPercentage"
                  value={t("productForm.label.vat") ?? ""}
                />
              </div>
              <TextInput
                id="vatPercentage"
                addon="%"
                type="number"
                {...methods.register("vatPercentage", {
                  valueAsNumber: true,
                })}
                errorMessage={methods.formState.errors.vatPercentage?.message}
              />
            </div>
          </div>
          <hr className="h-px mt-4 mb-4 bg-gray-200 border-0 dark:bg-gray-700" />
          <LanguageTabBar
            languages={supportedLanguages}
            tabs={[
              ...supportedLanguages.map((lang, index) => ({
                language: lang,
                hasError:
                  !!methods.formState.errors.descriptions?.[index]?.description
                    ?.message,
                content: (
                  <div className="flex gap-4">
                    <div className="w-full">
                      <div className="mb-2 block">
                        <Label
                          htmlFor={`descriptions.${index}.description`}
                          value={t("productForm.label.description") ?? ""}
                        />
                      </div>
                      <TextInput
                        autoFocus
                        id={`descriptions.${index}.description`}
                        type="text"
                        {...methods.register(
                          `descriptions.${index}.description`
                        )}
                        errorMessage={
                          methods.formState.errors.descriptions?.[index]
                            ?.description?.message
                        }
                      />
                    </div>
                  </div>
                ),
              })),
            ]}
          />
        </div>
        <div className="border-1 border-t p-6 dark:border-gray-700">
          <LoadingButton
            loading={isLoading}
            className="px-2 py-0.5"
            type="submit"
          >
            {t("productForm.button.save")}
          </LoadingButton>
        </div>
      </form>
      <DevTool control={methods.control} /> {/* set up the dev tool */}
    </FormProvider>
  );
};

export default ProductForm;
