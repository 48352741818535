import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { Table, TableProps } from "flowbite-react";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { AccentColor } from "hooks/templates/models/template.interface";
import { Currency, toCurrency } from "helpers/to-currency";
import { Product } from "hooks/products/models/product.interface";
import { Skeleton } from "components/skeletons/Skeleton";
import { SkeletonRow } from "components/skeletons/SkeletonRow";
import { SkeletonRows } from "components/skeletons/SkeletonRows";
import { useRoutes } from "domains";
import {
  WithAmount,
  WithNote,
} from "models/paymentDocument/paymentDocument.interface";

interface Props extends TableProps {
  rows: WithAmount<Partial<Product>>[];
  isLoading?: boolean;
  accentColor?: AccentColor;
  currency?: Currency;
}

const cellClassNames = "lg:px-2 2xl:px-6";

const OrderRow: FC<WithNote<WithAmount<Partial<Product>>>> = function ({
  id,
  quantity,
  name,
  reference,
  amountExclusiveVat = 0,
  vatPercentage = 0,
  description,
  currency,
  note,
}) {
  const { routes } = useRoutes();

  const rowPriceExclVat = Number(amountExclusiveVat) * quantity;
  const rowPriceInclVat =
    rowPriceExclVat + (rowPriceExclVat / 100) * vatPercentage;

  return (
    <Table.Row>
      <Table.Cell className={cellClassNames}>
        {id ? (
          <Link
            to={`${routes.products}/edit/${id}`}
            type="button"
            className={classnames("text-gray-500 font-semibold text-start", {
              "hover:underline": id,
              "cursor-default": !id,
            })}
          >
            {description || name}
          </Link>
        ) : (
          <span
            className={classnames("text-gray-500 font-semibold text-start", {
              "hover:underline": id,
              "cursor-default": !id,
            })}
          >
            {description || name}
          </span>
        )}
        <p className="text-gray-300">
          {reference}
          {note && (
            <span className="text-gray-500 text-xs font-light italic text-start">
              {note}
            </span>
          )}
        </p>
      </Table.Cell>
      <Table.Cell className={`text-gray-500 ${cellClassNames}`}>
        {toCurrency(Number(amountExclusiveVat), currency || Currency.Euro)}
      </Table.Cell>
      <Table.Cell className={`text-gray-500 ${cellClassNames}`}>
        {vatPercentage}%
      </Table.Cell>
      <Table.Cell className={`text-gray-500 ${cellClassNames}`}>
        {quantity}
      </Table.Cell>
      <Table.Cell className={`text-gray-500 text-right ${cellClassNames}`}>
        <strong>
          {toCurrency(rowPriceInclVat, currency || Currency.Euro)}
        </strong>
      </Table.Cell>
    </Table.Row>
  );
};

const LoadingOrderRows: FC = function () {
  return (
    <SkeletonRows rows={3}>
      <SkeletonRow>
        <Table.Cell>
          <div className="flex flex-col gap-4">
            <Skeleton isAnimated={false} className="dark:!bg-gray-200" />
            <Skeleton isAnimated={false} className="w-6/12 dark:!bg-gray-200" />
          </div>
        </Table.Cell>
        <Table.Cell>
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{toCurrency(10000, Currency.Euro)}</span>
          </Skeleton>
        </Table.Cell>
        <Table.Cell>
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>10</span>
          </Skeleton>
        </Table.Cell>
        <Table.Cell>
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>10</span>
          </Skeleton>
        </Table.Cell>
        <Table.Cell>
          <Skeleton isText isAnimated={false} className="dark:!bg-gray-200">
            <span>{toCurrency(1000, Currency.Euro)}</span>
          </Skeleton>
        </Table.Cell>
      </SkeletonRow>
    </SkeletonRows>
  );
};

export const OrderTable: FC<Props> = function ({
  rows,
  isLoading,
  currency,
  accentColor = AccentColor.Gray,
  ...rest
}) {
  const { t } = useTranslation();

  const headCellClassName = useMemo(
    () =>
      classnames(cellClassNames, {
        "bg-red-50 dark:!bg-red-50": accentColor === AccentColor.Red,
        "bg-orange-50 dark:!bg-orange-50": accentColor === AccentColor.Orange,
        "bg-yellow-50 dark:!bg-yellow-50": accentColor === AccentColor.Yellow,
        "bg-green-50 dark:!bg-green-50": accentColor === AccentColor.Green,
        "bg-blue-50 dark:!bg-blue-50": accentColor === AccentColor.Blue,
        "bg-purple-50 dark:!bg-purple-50": accentColor === AccentColor.Purple,
        "bg-gray-50 dark:!bg-gray-50": accentColor === AccentColor.Gray,
      }),
    [accentColor]
  );

  return (
    <Table {...rest}>
      <Table.Head className="dark:text-gray-700 dark:!bg-gray-50">
        <Table.HeadCell className={headCellClassName}>
          {t("orderTable.title.products")}
        </Table.HeadCell>
        <Table.HeadCell className={headCellClassName}>
          {t("orderTable.title.price")}
        </Table.HeadCell>
        <Table.HeadCell className={headCellClassName}>
          {t("orderTable.title.vat")}
        </Table.HeadCell>
        <Table.HeadCell className={headCellClassName}>#</Table.HeadCell>
        <Table.HeadCell className={`${headCellClassName} text-right`}>
          {t("orderTable.title.total")}
        </Table.HeadCell>
      </Table.Head>
      <Table.Body>
        {isLoading && <LoadingOrderRows />}
        {!isLoading &&
          rows.map((order, index) => (
            <OrderRow
              key={`${order.id} + ${new Date().toString() + index}`}
              currency={currency}
              {...order}
            />
          ))}
      </Table.Body>
    </Table>
  );
};
