/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from "i18next";
import { FieldError, FieldValues, Resolver } from "react-hook-form";

import { createSchemaValidator } from "../createSchemaValidator";
import { DeepRecord } from "../models/deepRecord";
import { Rule } from "../models/rule";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "../models/validationSchema";
import { DeepPartial } from "../models/deepPartial";

const isGenerator = <Input>(
  schema: HTMLValidationSchema<Input> | HTMLValidationSchemaGenerator<Input>
): schema is HTMLValidationSchemaGenerator<Input> =>
  typeof schema === "function";

const validateRule = <Value extends string | number | undefined>(
  value: Value,
  rule: Rule
): FieldError | undefined => {
  if (rule.required && (!value || String(value).trim() === "")) {
    return {
      type: "required",
      message: rule.valueMissing || (t("error.valueMissing") as string),
    };
  }

  if (value) {
    if (typeof value === "string") {
      if (rule.minLength && value.length < rule.minLength) {
        return {
          type: "minLength",
          message: rule.tooShort || (t("error.tooShort") as string),
        };
      }

      if (rule.maxLength && value.length > rule.maxLength) {
        return {
          type: "maxLength",
          message: rule.tooLong || (t("error.tooLong") as string),
        };
      }

      if (rule.pattern && !value.match(rule.pattern)) {
        return {
          type: "pattern",
          message:
            rule.patternMismatch || (t("error.patternMismatch") as string),
        };
      }
    }

    if (rule.min && value < rule.min) {
      return {
        type: "rangeUnderflow",
        message: rule.rangeUnderflow || (t("error.rangeUnderflow") as string),
      };
    }

    if (rule.max && value > rule.max) {
      return {
        type: "rangeOverflow",
        message: rule.rangeOverflow || (t("error.rangeOverflow") as string),
      };
    }
  }

  return undefined;
};

const toErrors = <Input extends Record<string, unknown>>(
  errors: DeepPartial<DeepRecord<Input, FieldError | undefined>>
): Awaited<ReturnType<Resolver<Input>>>["errors"] => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const entries = Object.entries(errors as any);

  const entryIsNotUndefined = <Value>([, value]: [
    string,
    Value | undefined
  ]): boolean => value !== undefined;

  return Object.fromEntries(entries.filter(entryIsNotUndefined));
};

/**
 * Resolver to validate a 'HTMLValidationSchema' object with 'react-hook-form'.
 * @example
 * const form = useForm({
 *   resolver: HTMLValidationSchemaResolver(schema)
 * });
 */
export const HTMLValidationSchemaResolver =
  <
    Input extends FieldValues,
    Schema extends
      | HTMLValidationSchema<Input>
      | HTMLValidationSchemaGenerator<Input>
  >(
    schema: Schema
  ): Resolver<Input> =>
  (input) => {
    const validateSchema = createSchemaValidator(validateRule);
    const newSchema: HTMLValidationSchema<Input> = isGenerator<Input>(schema)
      ? schema(input)
      : (schema as HTMLValidationSchema<Input>);

    return {
      values: input,
      errors: toErrors(validateSchema(input as any, newSchema)),
    };
  };
