import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
// import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import { api } from "helpers/api";
import { JournalContext } from "context/JournalContext";
import { JournalTypes } from "hooks/journals/models/journal.interface";
import { CreateInvoiceInput } from "models/paymentDocument/invoice/invoiceInput.interface";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
// import { useTemplates } from "domains";

import { SalesInvoice } from "../models/salesInvoice.interface";

export type CreateSalesInvoiceBody = CreateInvoiceInput;

export type CreateSalesInvoiceResponse = Omit<SalesInvoice, "update">;

export function useCreateSalesInvoice() {
  const queryClient = useQueryClient();
  const { journal } = useContext(JournalContext);
  const journalId = journal[JournalTypes.SALES];
  // const { create: createTemplate } = useTemplates();
  // const { t } = useTranslation();

  return useMutation<CreateSalesInvoiceResponse, Error, CreateSalesInvoiceBody>(
    "createSalesInvoice",
    async (input: CreateSalesInvoiceBody) => {
      // const template = input.template.id
      //   ? input.template
      //   : await createTemplate.execute({
      //       accentColor: input.template.accentColor,
      //       layout: input.template.layout,
      //     });

      // if (!template) {
      //   throw new Error(
      //     t("domain.salesInvoice.error.templateNotFound") as string
      //   );
      // }

      const response = await api.post("api/v1/sales-invoices", {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          journalId,
          reference: input.reference,
          customerId: input.customerId,
          issueDate: format(new Date(input.issueDate), "dd/MM/yyyy"),
          dueDate: format(new Date(input.dueDate), "dd/MM/yyyy"),
          currency: "EUR",
          extraInformation: input.extraInformation ?? "",
          documentLines: input.documentLines,
          invoicePayStatus: input.invoicePayStatus,
          ...(input.invoicePayStatus === InvoicePayStatus.Paid &&
          input.freeCommunication
            ? { freeCommunication: input.freeCommunication }
            : { freeCommunication: null }),
          ...(input.invoicePayStatus === InvoicePayStatus.Paid &&
          input.structuredCommunication
            ? { structuredCommunication: input.structuredCommunication }
            : { structuredCommunication: null }),
          ...(input.invoicePayStatus === InvoicePayStatus.Paid && input.payDate
            ? { payDate: format(new Date(input.payDate), "dd/MM/yyyy") }
            : { payDate: null }),
          ...(input.invoicePayStatus === InvoicePayStatus.Paid &&
          input.invoicePayType
            ? { invoicePayType: input.invoicePayType }
            : { invoicePayType: null }),
        }),
      });

      if (response.ok) {
        return response.json();
      }

      throw new Error(response.statusText);
    },
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries(["salesInvoices"]),
          queryClient.invalidateQueries(["templates"]),
        ]),
    }
  );
}
