import { useCallback, useEffect, useState, useContext } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiDocumentText } from "react-icons/hi";
import { usePurchaseInvoices, useRoutes, useTables } from "domains";
import { ActionBar, PageHeader } from "components/PageHeader";
import { DeleteModal } from "components/modals/DeleteModal";
import { EditModal } from "components/modals/EditModal";
import { AUTO_REFRESH_INTERVAL_KEY } from "components/AutoRefresh";
import { NoHeaderModal } from "components/modals/NoHeaderModal";
import { UploadFile } from "components/uploadFile";
import { UploadSuccess } from "components/UploadSuccess";
import { UploadCSV } from "components/UploadCSV";
import { JournalTypes } from "hooks/journals/models/journal.interface";
import { useTablesEffects } from "hooks/tables/useTablesUseCases";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import { Button } from "components/Button";
import { JournalContext } from "context/JournalContext";
import { useGetPurchaseInvoices } from "./queries/useGetPurchaseInvoices";
import PurchaseInvoiceTable from "./components/PurchaseInvoiceTable";
import {
  PURCHASE_INVOICES_XSLX_KEY,
  useGetPurchaseInvoicesXLSX,
} from "./queries/useGetPurchaseInvoicesXLSX";
import { SendToAccountantModal } from "./components/sendToAccountantModal";

const maxFileSize = 10 * 1024 * 1024; // 30MB
const maxFileCount = 20;

function PurchaseInvoicesPage() {
  const [searchParams] = useSearchParams();
  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [refetchInterval, setRefetchInterval] = useState<number>(
    parseInt(localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "", 10)
  );
  const purchaseInvoicesTable = useTables();
  const {
    routes,
    goToPurchaseInvoices,
    goToPurchaseInvoicesSendToAccountant,
    openCreateModal,
  } = useRoutes();
  const { t } = useTranslation();
  const { rowSelectedState, handleAllRowsClicked, handleOneRowClicked } =
    purchaseInvoicesTable;
  useTablesEffects(purchaseInvoicesTable);

  const { journal } = useContext(JournalContext);
  const journalId = journal[JournalTypes.PURCHASE];

  const getPurchaseInvoices = useGetPurchaseInvoices({
    limit: Number(searchParams.get("limit")) || 10,
    offset: Number(searchParams.get("offset")) || 0,
    search: searchParams.get("search") || "",
    invoicePayStatus:
      (searchParams.get("invoicePayStatus") as InvoicePayStatus) || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
    journalId,
    refetchInterval,
  });

  const getXLSX = useGetPurchaseInvoicesXLSX({
    search: searchParams.get("search") || "",
    sortKey: searchParams.get("sortKey") || "",
    sortOrder: searchParams.get("sortOrder") || "",
  });

  const {
    purchaseInvoices,
    count,
    upload,
    deleteMany,
    isLoading,
    uploadCSV,
    showSuccessModal,
    hideSuccessModal,
    sendToAccountant,
    error,
  } = usePurchaseInvoices(getPurchaseInvoices);

  const { action } = useParams();

  const onUploadPurchaseInvoices = useCallback(
    (files: File[]) => {
      files.forEach((file, i) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("journalId", journalId);
        upload.execute({ formData, isLast: Boolean(i >= files.length - 1) });
      });
    },
    [upload, journalId]
  );

  const onDeletePurchaseInvoice = useCallback(
    () => deleteMany.execute({ ids: idsToDelete }),
    [idsToDelete, deleteMany]
  );

  const uploadNew = useCallback(() => {
    hideSuccessModal();
    openCreateModal();
  }, [hideSuccessModal, openCreateModal]);

  useEffect(() => {
    if (action === "delete") {
      const ids = searchParams.getAll("idsToDelete");
      setIdsToDelete(ids);
    }
  }, [action, setIdsToDelete, searchParams]);

  const onSendToAccountant = useCallback(
    () => sendToAccountant.execute({ ids: rowSelectedState?.ids }),
    [rowSelectedState, sendToAccountant]
  );

  return (
    <div className="flex flex-col">
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("pageHeader.title.purchaseInvoices")}
        breadcrumbs={[
          {
            href: routes.invoices,
            label: t("sidebar.title.invoices"),
            icon: HiDocumentText,
          },
          {
            href: routes.purchaseInvoices,
            label: t("sidebar.link.purchaseInvoices"),
          },
        ]}
      >
        <ActionBar
          showBulkDelete
          pageKey="purchaseInvoices"
          rowSelectedState={rowSelectedState}
          onRefetch={getPurchaseInvoices.refetch}
          onRefetchIntervalChange={setRefetchInterval}
          dropdownKey="invoicePayStatus"
          dropdownOptions={Object.values(InvoicePayStatus).map((value) => ({
            key: value,
            value: t(`purchaseInvoicesPage.dropdown.invoicePayStatus.${value}`),
          }))}
          queryKey={PURCHASE_INVOICES_XSLX_KEY}
          showTimeFilter
          actions={
            <div className="flex gap-4">
              <Button
                onClick={goToPurchaseInvoicesSendToAccountant}
                type="button"
                className="!p-0 border-0"
              >
                Send to accountant
              </Button>
            </div>
          }
          {...getXLSX}
        />
      </PageHeader>
      <PurchaseInvoiceTable
        rows={purchaseInvoices}
        isLoading={isLoading}
        filteredOnStatus={
          searchParams.get("invoicePayStatus") as InvoicePayStatus
        }
        limit={Number(searchParams.get("limit")) || 10}
        total={count}
        handleAllRowsClicked={handleAllRowsClicked}
        handleOneRowClicked={handleOneRowClicked}
        rowSelectedState={rowSelectedState}
      />
      {action === "delete" && !!idsToDelete && (
        <DeleteModal
          isLoading={isLoading}
          isDeleting={deleteMany.isLoading}
          isError={!!error}
          isMultiple={idsToDelete.length > 1}
          page="purchaseInvoices"
          show={!!idsToDelete}
          onClose={goToPurchaseInvoices}
          onDelete={onDeletePurchaseInvoice}
        />
      )}
      {action === "create" && (
        <EditModal
          action={action as "create"}
          isError={!!error}
          page="purchaseInvoice"
          onClose={goToPurchaseInvoices}
          show
          showSubtitle
        >
          <UploadFile
            onUpload={onUploadPurchaseInvoices}
            isLoading={upload.isLoading}
            maxFileSize={maxFileSize}
            maxFileCount={maxFileCount}
            accept={["application/pdf", "image/*"]}
          />
        </EditModal>
      )}
      <NoHeaderModal
        show={showSuccessModal}
        onClose={hideSuccessModal}
        size="md"
      >
        <UploadSuccess
          onClose={hideSuccessModal}
          onUploadNew={uploadNew}
          message="purchaseInvoices"
        />
      </NoHeaderModal>
      <UploadCSV
        action={action}
        uploadCSV={uploadCSV}
        onClose={goToPurchaseInvoices}
        page="purchaseInvoices"
      />
      <NoHeaderModal
        show={action === "send-to-accountant"}
        onClose={goToPurchaseInvoices}
        size="md"
      >
        <SendToAccountantModal
          loading={sendToAccountant.isLoading}
          onClose={goToPurchaseInvoices}
          onSend={onSendToAccountant}
        />
      </NoHeaderModal>
    </div>
  );
}

export default PurchaseInvoicesPage;
