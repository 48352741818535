import { FC, PropsWithChildren, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { format, parse, addDays } from "date-fns";
import {
  HTMLValidationSchema,
  HTMLValidationSchemaGenerator,
} from "helpers/html-validation-schema";
import { HTMLValidationSchemaResolver } from "helpers/html-validation-schema/compat/HTMLValidationSchemaResolver";
// import {
//   AccentColor,
//   TemplateLayout,
// } from "hooks/templates/models/template.interface";
import { Quote } from "hooks/quotes/models/quote.interface";

import { QuoteFormValues } from "./QuoteForm";

interface Props {
  defaultValues?: Quote;
  schema:
    | HTMLValidationSchema<QuoteFormValues>
    | HTMLValidationSchemaGenerator<QuoteFormValues>;
}

export const getQuoteFormDefaultValues = (
  defaultValues?: Quote
): Partial<QuoteFormValues> => {
  return {
    ...defaultValues,
    customerId: defaultValues?.customerId ?? undefined,
    issueDate: defaultValues?.issueDate
      ? format(
          parse(defaultValues?.issueDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )
      : format(new Date(), "yyyy-MM-dd"),
    dueDate: defaultValues?.dueDate
      ? format(
          parse(defaultValues?.dueDate, "dd/MM/yyyy", new Date()),
          "yyyy-MM-dd"
        )
      : format(addDays(new Date(), 30), "yyyy-MM-dd"),
    documentLines: defaultValues?.documentLines ?? [
      { quantity: 1, vatPercentage: 21, articleNumber: "", description: "" },
    ],
    // template: {
    //   id: defaultValues?.template.id ?? "",
    //   accentColor: defaultValues?.template.accentColor ?? AccentColor.Gray,
    //   layout: defaultValues?.template.layout ?? TemplateLayout.Template1,
    // },
  };
};

export const QuoteFormProvider: FC<PropsWithChildren<Props>> = function ({
  defaultValues,
  schema,
  children,
}) {
  const methods = useForm<QuoteFormValues>({
    resolver: HTMLValidationSchemaResolver(schema),
    mode: "onBlur",
    defaultValues: getQuoteFormDefaultValues(defaultValues),
  });

  useEffect(() => {
    if (defaultValues?.id !== methods.getValues("id")) {
      methods.reset(getQuoteFormDefaultValues(defaultValues));
    }
  }, [defaultValues, methods]);

  return <FormProvider {...methods}>{children}</FormProvider>;
};
