import { Spinner } from "flowbite-react";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import addMinutes from "date-fns/addMinutes";
import { useInitiateConnection } from "../mutations/useInitiateConnection";

interface Props {
  show?: boolean;
}

export const InitiateConnection: FC<Props> = function ({ show }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const connectorId = searchParams.get("connectorId");
  const iban = searchParams.get("iban");

  const { mutate, data } = useInitiateConnection();

  useEffect(() => {
    if (show && mutate && connectorId) {
      mutate({ connectorId: String(connectorId), iban });
    }
  }, [show, mutate, connectorId, iban]);

  useEffect(() => {
    if (show && data?.authUrl && data?.flowContext) {
      localStorage.setItem(
        "ongoingBankConnection",
        JSON.stringify({
          expire: addMinutes(new Date(), 10).toISOString(),
          connectorId,
          flowContext: data.flowContext,
        })
      );
      window.open(data.authUrl, "_blank");
    }
  }, [connectorId, data, show]);

  return (
    <div className="flex flex-col justify-center items-center">
      <Spinner className="mr-4" size="lg" />
      <p className="text-gray-900 dark:text-white mt-3.5">
        {" "}
        {t("connections.initiating")}...
      </p>
      <p className="mb-4 font-light text-gray-500 dark:text-gray-300">
        {t("connections.windowCanBeClosed")}
      </p>
    </div>
  );
};
