import { Mutation, UseCases } from "models/core";
import { useAuth } from "hooks/useAuth";
import { PATTERN } from "helpers/pattern";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { useCallback } from "react";
import { HTMLValidationSchemaGenerator } from "helpers/html-validation-schema/models/validationSchema";
import { DomainMutation, useDomainMutation } from "hooks/useDomainMutation";
import {
  ForgotPasswordInput,
  LoginInput,
  RegisterInput,
  ResetPasswordInput,
} from "./models/authenticationInput.interface";
import { Role, User } from "./models/user.interface";

export type LoginMutation = Mutation<LoginInput, string>;
export type RegisterMutation = Mutation<RegisterInput>;
export type RequestPasswordResetMutation = Mutation<ForgotPasswordInput>;
export type ResetPasswordMutation = Mutation<ResetPasswordInput>;

interface Props {
  loginMutation: LoginMutation;
  registerMutation: RegisterMutation;
  requestPasswordResetMutation: RequestPasswordResetMutation;
  resetPasswordMutation: ResetPasswordMutation;
}

interface Result {
  user: User;
  login: DomainMutation<LoginInput>;
  register: DomainMutation<
    RegisterInput,
    HTMLValidationSchemaGenerator<RegisterInput>
  >;
  forgotPassword: DomainMutation<ForgotPasswordInput>;
  resetPassword: DomainMutation<
    ResetPasswordInput,
    HTMLValidationSchemaGenerator<ResetPasswordInput>
  >;
  logout: () => Promise<void>;
}

export const useAuthenticationUseCases: UseCases<Props, Result> = ({
  // loginMutation,
  registerMutation,
  requestPasswordResetMutation,
  resetPasswordMutation,
}) => {
  const { token, login: persistToken, logout: removeToken } = useAuth();
  const { t } = useTranslation();

  const user: Result["user"] = {
    role: !token ? Role.Guest : Role.Admin,
  };

  const login: Result["login"] = useDomainMutation<LoginInput>(
    {
      email: {
        required: true,
        valueMissing: t("domain.authentication.error.missingValue") as string,
        pattern: PATTERN.EMAIL.source,
        patternMismatch: t(
          "domain.authentication.error.emailPatternMismatch"
        ) as string,
      },
      password: {
        required: true,
        valueMissing: t("domain.authentication.error.missingValue") as string,
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async (input) => {
      try {
        // const token = await loginMutation(input);
        const token =
          "eyJraWQiOjEsImFsZyI6IlJTMjU2In0.eyJpc3MiOiJodHRwczovL2JhY2tlbmQuYXV0aC5zdGFnaW5nLnN1bXJpc2UuYmUvYXV0aGVudGljYXRpb24vYXBpL3YxIiwic3ViIjoiamVzc2UuZGVib2l0c2VsaWVyQHdpdGhlbGV2ZW4uY29tIiwibmJmIjoxNjk1Mjg1NDIyLCJpYXQiOjE2OTUyODU0MjIsImV4cCI6MTcwMzMyMDYyMiwiYXpwIjoiNmM0M2U3ZTQtMzk4Yy00ZWQyLWI4OWUtM2QxMzczYjBhZDI5IiwiYXVkIjoiYjRmZjI0ZjAtYTQwYS00MzU5LTk2OGQtODQzZGY4YWM5YWNmIiwiY29tcGFueSI6MTIzNDg4MDY3LCJzY29wZSI6IiJ9.oBLnyJ8EVJoHtuvsn1rxKc_70Eh3rIY941fvE36pazQETC02qVQKyTKvPTIkbMf-LBFa8o1-QFPfCdZYFP26zRJ0JEGcHyhd_xoViz_ObX1be_pFO3G0Nb3ZXH4yfHxPVRWwkXB3LTRhEYUzGdSSLa6ncYFAqiFQhPYy4JQHyFrCpXIYqI772K9vB9-AePgtL9_UCtcBXsljMA431MbZZIVagbfzD_RcPC4nohb8-yUxOipzvXgjsZzs9NXdkXYDI_9JNHIPyQEuI52IkWGl0z22q1Z6pOvomilM5LQjjSxX7hmpZZAgWu_Y355AZZtYH80RJsG8_2bm4gQ0zKiR5Q";
        if (token) persistToken(token);
      } catch {
        throw new Error(
          t("domain.authentication.error.invalidCredentials") as string
        );
      }
    }
  );

  const resetPassword: Result["resetPassword"] = useDomainMutation<
    ResetPasswordInput,
    HTMLValidationSchemaGenerator<ResetPasswordInput>
  >(
    (input) => ({
      password: login.schema.password,
      confirmPassword: {
        required: true,
        valueMissing: t("domain.authentication.error.missingValue") as string,
        pattern: new RegExp(`^${input.password}$`).source,
        patternMismatch: t(
          "domain.authentication.error.passwordsMustMatch"
        ) as string,
      },
    }),
    async (input) => {
      await resetPasswordMutation(input);

      event.emit(
        "mutationSucceeded",
        t("domain.authentication.alert.passwordHasBeenReset") as string
      );
      event.emit("passwordReset");
    }
  );

  const register: Result["register"] = useDomainMutation<
    RegisterInput,
    HTMLValidationSchemaGenerator<RegisterInput>
  >(
    (input) => ({
      ...login.schema,
      ...resetPassword.schema(input),
      termsAndConditions: {
        required: true,
        valueMissing: t(
          "domain.authentication.error.missingTermsAndConditionsValue"
        ) as string,
      },
    }),
    async (input) => {
      await registerMutation(input);

      event.emit(
        "mutationSucceeded",
        t("domain.authentication.alert.emailConfirmationMailSent") as string
      );
    }
  );

  const forgotPassword: Result["forgotPassword"] =
    useDomainMutation<ForgotPasswordInput>(
      {
        email: login.schema.email,
      },
      async (input) => {
        await requestPasswordResetMutation(input);

        event.emit(
          "mutationSucceeded",
          t("domain.authentication.alert.recoverPasswordMailSent") as string
        );
      }
    );

  const logout: Result["logout"] = useCallback(async () => {
    removeToken();
  }, [removeToken]);

  return {
    user,
    login,
    register,
    forgotPassword,
    resetPassword,
    logout,
  };
};
