import { Address } from "models/address/address.interface";
import { Template } from "hooks/templates/models/template.interface";
import { Currency } from "helpers/to-currency";

export enum InvoicePayType {
  Cash = "CASH",
  BankTransfer = "TRANSFER",
  CreditCard = "CREDITCARD",
}

export type WithAmount<Type> = Type & { quantity: number };
export type WithNote<Type> = Type & { note?: string };
export type WithQuantity<Type> = Type & { quantity: number };

export interface DocumentLine {
  articleNumber?: number | string;
  description?: string;
  reference?: string;
  note?: string;
  quantity: number;
  amountExclusiveVat?: number;
  vatPercentage?: number;
}

export interface PaymentDocumentBase {
  id: string;
  reference?: string;
  customerName?: string;
  customerId?: string;
  issueDate: string;
  sentDate?: string;
  billingAddress: Address;
  dueDate: string;
  totalInclusiveVat: number;
  vatPrice: number;
  invoiceNumber?: number;
  quoteNumber?: number;
  template: Omit<Template, "delete">;
  documentLines: DocumentLine[];
  currency: Currency;
  extraInformation?: string;
}
