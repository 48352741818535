import {
  ChangeEvent,
  ComponentProps,
  createRef,
  FC,
  PropsWithChildren,
  ReactElement,
  SVGProps,
  useCallback,
  useEffect,
} from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { HiOutlineRefresh, HiPlus, HiUpload, HiTrash } from "react-icons/hi";
import { Breadcrumb, Label, Tooltip } from "flowbite-react";
import { useDebouncedCallback } from "use-debounce";
import { useRoutes } from "domains";
import { RowSelectedState } from "hooks/tables/models/tables.interface";
import classNames from "classnames";
import { isAfter } from "date-fns";
import { event } from "event";
import { H1 } from "./headers/H1";
import { TextInput } from "./input/TextInput";
import { Select } from "./input/Select";
import Badge from "./Badge";
import {
  AUTO_REFRESH_INTERVAL_KEY,
  AutoRefresh,
  AutoRefreshOption,
} from "./AutoRefresh";
import { DownloadXLSX, DownloadXLSXProps } from "./DownloadXLSX";
import { Button } from "./Button";

export interface Breadcrumb {
  href?: string;
  label: string;
  icon?: FC<SVGProps<SVGSVGElement>>;
}

interface PageHeaderProps extends ComponentProps<"div"> {
  breadcrumbs: Breadcrumb[];
  title: string;
  badge?: string;
  badgeMap?: Map<string, string>;
  action?: ReactElement;
}

interface ActionBarProps {
  pageKey: string;
  rowSelectedState: RowSelectedState;
  dropdownKey?: string;
  dropdownOptions?: { key: string; value: string }[];
  onRefetch?: () => void;
  onRefetchIntervalChange?: (interval: number) => void;
  showTimeFilter?: boolean;
  showCreateButton?: boolean;
  showBulkDelete?: boolean;
  actions?: ReactElement;
  hideSearch?: boolean;
}

export const ActionBar: FC<ActionBarProps & DownloadXLSXProps> = function ({
  pageKey,
  rowSelectedState,
  dropdownKey,
  dropdownOptions,
  onRefetchIntervalChange,
  onRefetch,
  showTimeFilter = false,
  showCreateButton = true,
  actions,
  queryKey,
  hideSearch,
  showBulkDelete,
  ...rest
}) {
  const { t } = useTranslation();
  const { setSearchParam, openCreateModal, openDeleteModal, openUploadModal } =
    useRoutes();
  const [searchParams] = useSearchParams();
  const queryRef = createRef<HTMLInputElement>();
  const dropdownRef = createRef<HTMLSelectElement>();
  const timeRangeFromRef = createRef<HTMLInputElement>();
  const timeRangeToRef = createRef<HTMLInputElement>();

  useEffect(() => {
    if (queryRef.current) {
      queryRef.current.value = searchParams.get("search") || "";
    }
  }, [queryRef, searchParams]);

  useEffect(() => {
    if (dropdownRef.current && dropdownKey) {
      dropdownRef.current.value = searchParams.get(dropdownKey) || "";
    }
  }, [dropdownRef, searchParams, dropdownKey]);

  useEffect(() => {
    if (timeRangeFromRef.current) {
      timeRangeFromRef.current.value = searchParams.get("from") || "";
    }
  }, [searchParams, timeRangeFromRef]);

  useEffect(() => {
    if (timeRangeToRef.current) {
      timeRangeToRef.current.value = searchParams.get("to") || "";
    }
  }, [searchParams, timeRangeToRef]);

  const changeQuery = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      setSearchParam("search", target.value);
    },
    [setSearchParam]
  );

  const changeDropdown = useCallback(
    ({ target }: ChangeEvent<HTMLSelectElement>) => {
      setSearchParam(dropdownKey ?? "", target.value);
    },
    [setSearchParam, dropdownKey]
  );

  const changeTimeRange = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const input = target.id as "from" | "to";

      const { value } = target;
      const otherDate =
        input === "to"
          ? timeRangeFromRef?.current?.value ?? ""
          : timeRangeToRef?.current?.value ?? "";

      const isInvalidDate =
        input === "to"
          ? isAfter(new Date(otherDate), new Date(value))
          : isAfter(new Date(value), new Date(otherDate));

      if (value && otherDate.length > 0 && isInvalidDate) {
        // Emit error toast
        event.emit(
          "validationFailed",
          new Error(t(`pageHeader.error.${input}.invalid`) as string)
        );

        // Clear input
        if (input === "to" && timeRangeToRef?.current) {
          timeRangeToRef.current.value = "";
        } else if (input === "from" && timeRangeFromRef?.current) {
          timeRangeFromRef.current.value = "";
        }

        // Clear search param
        setSearchParam(input, "");

        return;
      }

      setSearchParam(input, target.value);
    },
    [setSearchParam, t, timeRangeFromRef, timeRangeToRef]
  );

  const debounceChange = useDebouncedCallback(changeQuery, 500);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onOpenDeleteModal = useCallback(
    () =>
      rowSelectedState.ids?.length
        ? openDeleteModal(rowSelectedState.ids)
        : null,
    [openDeleteModal, rowSelectedState]
  );

  const onRefetchIntervalChangeHandler = useCallback(
    (input: AutoRefreshOption) => {
      onRefetchIntervalChange?.(input);
      localStorage.setItem(AUTO_REFRESH_INTERVAL_KEY, input.toString());
    },
    [onRefetchIntervalChange]
  );

  return (
    <div className="flex flex-col gap-4">
      {showTimeFilter && (
        <div className="flex w-full">
          <div className="ml-auto">
            {rowSelectedState?.ids?.length > 0 && actions ? actions : undefined}
          </div>
          {showCreateButton && (
            <div className="ml-4">
              <Button
                onClick={openCreateModal}
                type="button"
                className="!p-0 border-0"
              >
                <HiPlus className="mr-2 h-5 w-5" />
                {t(`pageHeader.createText.${pageKey}`)}
              </Button>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames(
          "flex w-full items-center text-gray-500 dark:text-gray-300 gap-4",
          {
            "flex-col md:flex-row": showTimeFilter,
          }
        )}
      >
        {!hideSearch && (
          <TextInput
            id="query"
            type="text"
            sizing="md"
            className={classNames("shrink-1", {
              "w-full md:w-44": showTimeFilter,
              "w-52": !showTimeFilter,
            })}
            placeholder={t(`pageHeader.placeholder.${pageKey}`) ?? ""}
            onChange={debounceChange}
            ref={queryRef}
          />
        )}
        {dropdownKey && (
          <Select
            onChange={changeDropdown}
            className={classNames("shrink-1", {
              "w-full md:w-32": showTimeFilter,
              "w-44": !showTimeFilter,
            })}
            ref={dropdownRef}
          >
            <option value="">
              {t(`pageHeader.placeholder.${dropdownKey}`) ?? ""}
            </option>
            {dropdownOptions?.map((option) => (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            ))}
          </Select>
        )}
        {showTimeFilter && (
          <div className="shrink-1 w-full md:w-64 lg:w-70 xl:w-96 flex flex-col sm:flex-row flex-nowrap gap-4">
            <div className="relative w-full sm:w-1/2 md:w-28 lg:w-32 xl:w-40 shrink-1">
              <Label
                htmlFor="from"
                className="absolute z-10 left-0 -top-1 md:-top-6"
              >
                {t("pageHeader.placeholder.from")}
              </Label>
              <TextInput
                type="date"
                placeholder={t("pageHeader.placeholder.from") as string}
                id="from"
                className="mt-6 md:mt-0"
                ref={timeRangeFromRef}
                onChange={changeTimeRange}
              />
            </div>
            <div className="relative w-full sm:w-1/2 md:w-28 lg:w-32 xl:w-40 shrink-1">
              <Label
                htmlFor="to"
                className="absolute z-10 left-0 -top-1 md:-top-6"
              >
                {t("pageHeader.placeholder.to")}
              </Label>
              <TextInput
                type="date"
                placeholder={t("pageHeader.placeholder.to") as string}
                id="to"
                className="mt-6 md:mt-0"
                ref={timeRangeToRef}
                onChange={changeTimeRange}
              />
            </div>
          </div>
        )}
        <div className="border-l border-gray-200 dark:border-gray-700 h-6 flex items-center pl-1 ml-4">
          {showBulkDelete && (
            <Tooltip content={t(`pageHeader.tooltip.delete.${pageKey}`)}>
              <Button
                onClick={onOpenDeleteModal}
                disabled={!rowSelectedState.ids?.length}
                color="gray-500 dark:gray-300"
                className="cursor-pointer rounded !p-0 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 disabled:cursor-not-allowed disabled:hover:none"
                aria-label="pageHeader.buttons.delete"
              >
                <HiTrash size="24" />
              </Button>
            </Tooltip>
          )}
          {queryKey && (
            <Tooltip content={t(`pageHeader.tooltip.export.${pageKey}`)}>
              <DownloadXLSX {...rest} queryKey={queryKey} disabled />
            </Tooltip>
          )}
          {queryKey && (
            <Tooltip content={t(`pageHeader.tooltip.import.${pageKey}`)}>
              <Button
                disabled
                onClick={openUploadModal}
                color="gray-500 dark:gray-300"
                className="cursor-pointer rounded !p-0 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700"
              >
                <HiUpload size="24" />
              </Button>
            </Tooltip>
          )}
          <Tooltip content={t(`pageHeader.tooltip.refresh.${pageKey}`)}>
            <Button
              onClick={onRefetch}
              color="gray-500 dark:gray-300"
              className="cursor-pointer rounded !p-0 text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-400 dark:hover:bg-gray-700 disabled:cursor-not-allowed disabled:hover:none"
              aria-label="pageHeader.buttons.refresh"
            >
              <HiOutlineRefresh size="24" />
            </Button>
          </Tooltip>
          {onRefetchIntervalChange && (
            <div className="ml-2">
              <AutoRefresh
                value={
                  parseInt(
                    localStorage.getItem(AUTO_REFRESH_INTERVAL_KEY) ?? "",
                    10
                  ) as AutoRefreshOption
                }
                onChange={onRefetchIntervalChangeHandler}
              />
            </div>
          )}
        </div>
        {!showTimeFilter && showCreateButton && (
          <div className="ml-auto">
            <Button
              onClick={openCreateModal}
              type="button"
              className="!p-0 border-0"
            >
              <HiPlus className="mr-2 h-5 w-5" />
              {t(`pageHeader.createText.${pageKey}`)}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const PageHeader: FC<PropsWithChildren<PageHeaderProps>> = function ({
  breadcrumbs,
  title,
  badge,
  badgeMap,
  children,
  action,
  ...rest
}) {
  return (
    <div
      {...rest}
      className={classNames(
        "flex flex-col p-4 gap-4 border-b border-gray-200 dark:border-gray-700",
        rest.className
      )}
    >
      <Breadcrumb className="h-6 flex">
        {breadcrumbs.map(({ href, label, icon }) => (
          <Breadcrumb.Item key={label} icon={icon}>
            {href ? (
              <Link className="text-black dark:text-white" to={href}>
                {label}
              </Link>
            ) : (
              <span>{label}</span>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <div className="flex flex-col gap-1">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <H1>{title}</H1>
            {badge && badgeMap && (
              <span className="mb-3">
                <Badge value={badge} map={badgeMap} />
              </span>
            )}
          </div>
          {action}
        </div>
        {children}
      </div>
    </div>
  );
};
