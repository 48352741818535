import { useContext } from "react";
import { useQueryClient } from "react-query";
import { httpErrorCodeWithTranslations } from "helpers/api";
import { useTranslation } from "react-i18next";
import { event } from "event";
import { format } from "date-fns";
import { useApiMutation } from "hooks/api/useApiMutation";
import { Currency } from "helpers/to-currency";
import { JournalContext } from "context/JournalContext";
import { JournalTypes } from "hooks/journals/models/journal.interface";
import { CreateQuoteInput } from "models/paymentDocument/quote/quoteInput.interface";
import { Quote } from "../models/quote.interface";

export type CreateQuoteResponse = Quote;
export type CreateQuoteBody = CreateQuoteInput;

export function useCreateQuote() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { journal } = useContext(JournalContext);
  const journalId = journal[JournalTypes.SALES];

  return useApiMutation<CreateQuoteResponse, CreateQuoteInput>(
    "createQuote",
    "quotes",
    (input) => ({
      body: JSON.stringify({
        customerId: input.customerId,
        documentLines: input.documentLines,
        issueDate: input.issueDate
          ? format(new Date(input.issueDate), "dd/MM/yyyy")
          : "",
        dueDate: input.dueDate
          ? format(new Date(input.dueDate), "dd/MM/yyyy")
          : "",
        currency: Currency.Euro,
        journalId,
      }),
    }),
    {
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries(["quotes"]),
          queryClient.invalidateQueries(["templates"]),
        ]);
      },
      onError: (error) => {
        // For the time being: error messages are shown from the back-end (still in english).
        // They should be able to provide translated error messages since we
        // send the current language over the "Accept-Language" header
        // See: src/helpers/api.ts

        // They'll need to provide they want us
        // to translate messages on the front-end

        if (error?.response?.payload?.detail) {
          event.emit(
            "mutationFailed",
            new Error(error?.response?.payload?.detail)
          );

          return;
        }
        if (httpErrorCodeWithTranslations.includes(error.response.status)) {
          event.emit(
            "mutationFailed",
            new Error(t(`error.${error.response.status}`) as string)
          );

          return;
        }

        event.emit("mutationFailed", new Error(error.message));
      },
    },
    async (response) => {
      return response.json();
    }
  );
}
