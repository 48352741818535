import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiAdjustments } from "react-icons/hi";
import { useFile, useRoutes } from "domains";
import { PageHeader } from "components/PageHeader";
import { useGetFile } from "hooks/file/queries/useGetFile";
import { Card } from "flowbite-react";
import { CompanyForm } from "components/company/CompanyForm";
import { UpdateFileInput } from "hooks/file/models/file.interface";
import { removeProperties } from "helpers/remove-properties";
import { CompanyLogo } from "components/company/CompanyLogo";

const SettingsPage: FC = function () {
  const { t } = useTranslation();
  const { routes } = useRoutes();
  const { companyLogo, address, update, ...file } = useFile(useGetFile());

  const fileFormValues = useMemo(() => {
    if (!file) {
      return undefined;
    }

    return removeProperties({ ...file, billingAddress: address }, ["theme"]);
  }, [file, address]);

  const submitUpdateForm = useCallback(
    (input: UpdateFileInput) => {
      if (!update) {
        return;
      }

      update?.execute(input);
    },
    [update]
  );

  return (
    <>
      <PageHeader
        className="bg-white dark:bg-gray-800"
        title={t("settingsPage.title.settings")}
        breadcrumbs={[
          {
            href: routes.settings,
            label: t("sidebar.title.settings") as string,
            icon: HiAdjustments,
          },
        ]}
      />
      <div
        className="m-4 grid grid-cols-1 xl:grid-cols-5 gap-4"
        data-testid="root"
      >
        <div className="col-span-1 xl:col-span-3 flex flex-col gap-4">
          <Card>
            <CompanyForm
              defaultValues={fileFormValues}
              isLoading={update.isLoading}
              schema={update.schema}
              onSubmit={submitUpdateForm as (input: UpdateFileInput) => void}
            />
          </Card>
        </div>
        <div className="justify-start h-fit col-span-1 xl:col-span-2">
          <Card>
            <CompanyLogo logo={companyLogo} />
          </Card>
        </div>
      </div>
    </>
  );
};

export default SettingsPage;
