import { useApiQuery } from "hooks/api/useApiQuery";

import { GetKBOLookupResponse } from "../models/KBOLookup.interface";

export interface GetKBOLookupParams {
  search: string;
}

export const KBO_LOOKUP_QUERY = "KBOLookups";

export function useGetKBOLookup(
  enabled: boolean,
  formKey: string,
  search?: string
) {
  return useApiQuery<GetKBOLookupResponse>(
    [KBO_LOOKUP_QUERY, formKey],
    `lookups/company?search=${search ?? ""}`,
    {},
    {
      enabled,
      retry: false,
      // silently fail (no error messages)
      onError: () => null,
    }
  );
}
