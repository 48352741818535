import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { event } from "event";
import { useEvent } from "hooks/useEvent";
import { UseCases } from "models/core";
import { Customer } from "hooks/customers/models/customer.interface";
import { PurchaseInvoice } from "hooks/purchaseInvoices/models/purchaseInvoice.interface";
import { SalesInvoice } from "hooks/salesInvoices/models/salesInvoice.interface";
import { Quote } from "hooks/quotes/models/quote.interface";
import { Supplier } from "hooks/suppliers/models/supplier.interface";
import { Product } from "hooks/products/models/product.interface";
import { InvoicePayStatus } from "models/paymentDocument/invoice/invoice.interface";
import { Routes } from "./models/routes.interface";

export enum ActionPath {
  Detail = "detail",
  Create = "create",
}

export enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}

interface Result {
  routes: Routes;
  currentURL: string;
  redirectURL: string | undefined;
  action?: ActionPath;
  goToCompanySettings: (action?: string) => void;
  goToBankAccounts: (action?: string) => void;
  goToTransactions: () => void;
  goToSubscription: () => void;
  goToProducts: () => void;
  goToProduct: (id: Product["id"]) => void;
  goToProductGroups: () => void;
  goToSuppliers: () => void;
  goToSupplier: (id: Supplier["id"]) => void;
  goToCustomers: () => void;
  goToCustomer: (id: Customer["id"]) => void;
  goToPurchaseInvoices: () => void;
  goToPurchaseInvoicesSendToAccountant: () => void;
  goToPurchaseInvoice: (id: PurchaseInvoice["id"]) => void;
  goToSalesInvoices: () => void;
  goToSalesInvoicesSendToAccountant: () => void;
  goToSalesInvoicesSendToCustomer: () => void;
  goToSalesInvoice: (id: SalesInvoice["id"]) => void;
  goToQuotes: () => void;
  goToQuote: (id: Quote["id"]) => void;
  goToTimesheets: () => void;
  goToInvoicesFromDashboard: (
    path: string,
    status: InvoicePayStatus,
    from?: string,
    to?: string
  ) => void;
  openCreateModal: () => void;
  openPayModal: () => void;
  openDetailCreateModal: (subtype: string) => void;
  openDeleteModal: (ids: string[]) => void;
  openDetailDeleteModal: (subtype: string, id: string) => void;
  openEditModal: (id: string) => void;
  openViewModal: (id: string) => void;
  openDetailEditModal: (subtype: string, id: string) => void;
  openConvertModal: () => void;
  openSendModal: () => void;
  openRemindModal: () => void;
  openGenerateInvoiceModal: (id: string) => void;
  openUploadModal: () => void;
  openCustomProductModal: (index: number, initialValue: string) => void;
  openNoteModal: (index: number) => void;
  openDifferentEntityModal: (
    entityParam: string,
    initialValue?: string
  ) => void;
  closeDetailModal: () => void;
  closeEditDetailModal: () => void;
  closeCustomProduct: () => void;
  closePayModal: () => void;
  closeDifferentEntityModal: (entityParam: string) => void;
  setSearchParam: (key: string, query: string) => void;
  setPaginationParams: (offset: number) => void;
  setLimitParams: (limit: number) => void;
  setSortParams: (sortKey: string) => void;
  setRouteParams: (key: string, value: string) => void;
}

export const useRoutesUseCases: UseCases<undefined, Result> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const currentURL: Result["currentURL"] = `${location.pathname}${location.search}`;
  const redirectURL: Result["redirectURL"] =
    new URLSearchParams(location.search).get("redirect") || undefined;

  const routes: Result["routes"] = useMemo(
    () => ({
      index: "/",
      settings: "/settings",
      companySettings: "/settings/company",
      invoiceSettings: "/settings/invoice",
      dashboard: "/dashboard",
      quotes: "/quotes",
      quote: "/quotes/detail/:id",
      createQuote: "/quotes/create",
      invoices: "/invoices",
      billing: "/billing",
      products: "/billing/products",
      productGroups: "/billing/product-groups",
      suppliers: "/suppliers",
      supplier: "/suppliers/detail/:id",
      createSupplier: "/suppliers/create",
      customers: "/customers",
      customer: "/customers/detail/:id",
      createCustomer: "/customers/create",
      salesInvoices: "/invoices/sales",
      salesInvoice: "/invoices/sales/detail/:id",
      createSalesInvoice: "/invoices/sales/create",
      purchaseInvoices: "/invoices/purchase",
      purchaseInvoice: "/invoices/purchase/detail/:id",
      timesheets: "/timesheets",
      bankAccounts: "/settings/bank-accounts",
      transactions: "/transactions",
      subscription: "/settings/subscription",
      stripCallback: "/stripe/callback",
    }),
    []
  );

  const action = useMemo(() => {
    if (location.pathname.includes("detail")) return ActionPath.Detail;
    if (location.pathname.includes("create")) return ActionPath.Create;
    return undefined;
  }, [location.pathname]);

  const goToBankAccounts: Result["goToBankAccounts"] = useCallback(
    (action?: string) => {
      navigate(
        `${routes.bankAccounts}${typeof action === "string" ? action : ""}`
      );
    },
    [navigate, routes]
  );

  const goToCompanySettings: Result["goToCompanySettings"] = useCallback(
    (action?: string) => {
      navigate(
        `${routes.companySettings}${typeof action === "string" ? action : ""}`
      );
    },
    [navigate, routes]
  );

  const goToTransactions: Result["goToTransactions"] = useCallback(() => {
    navigate(routes.transactions);
  }, [navigate, routes]);

  const goToProducts: Result["goToProducts"] = useCallback(() => {
    searchParams.delete("idsToDelete");
    navigate(`${routes.products}?${searchParams.toString()}`);
  }, [navigate, searchParams, routes]);

  const goToProduct: Result["goToProduct"] = useCallback(
    (id) =>
      navigate(`${routes.products}/edit/${id}?${searchParams.toString()}`),
    [navigate, searchParams, routes]
  );

  const goToProductGroups: Result["goToProductGroups"] = useCallback(() => {
    searchParams.delete("idsToDelete");
    navigate(`${routes.productGroups}?${searchParams.toString()}`);
  }, [navigate, searchParams, routes]);

  const goToSuppliers: Result["goToSuppliers"] = useCallback(() => {
    searchParams.delete("idsToDelete");
    navigate(`${routes.suppliers}?${searchParams.toString()}`);
  }, [navigate, searchParams, routes]);

  const goToSupplier: Result["goToSupplier"] = useCallback(
    (id: string) =>
      navigate(
        `${routes.supplier.replace(":id", id)}?${searchParams.toString()}`
      ),
    [navigate, routes, searchParams]
  );

  const goToCustomers: Result["goToCustomers"] = useCallback(() => {
    searchParams.delete("idsToDelete");
    navigate(`${routes.customers}?${searchParams.toString()}`);
  }, [navigate, searchParams, routes]);

  const goToCustomer: Result["goToCustomer"] = useCallback(
    (id) =>
      navigate(
        `${routes.customer.replace(":id", id)}?${searchParams.toString()}`
      ),
    [navigate, searchParams, routes]
  );

  const goToPurchaseInvoices: Result["goToPurchaseInvoices"] =
    useCallback(() => {
      searchParams.delete("idsToDelete");
      navigate(`${routes.purchaseInvoices}?${searchParams.toString()}`);
    }, [navigate, searchParams, routes]);

  const goToPurchaseInvoicesSendToAccountant: Result["goToPurchaseInvoicesSendToAccountant"] =
    useCallback(() => {
      navigate(
        `${
          routes.purchaseInvoices
        }/send-to-accountant?${searchParams.toString()}`
      );
    }, [navigate, routes, searchParams]);

  const goToPurchaseInvoice: Result["goToPurchaseInvoice"] = useCallback(
    (id) =>
      navigate(
        `${routes.purchaseInvoice.replace(
          ":id",
          id
        )}?${searchParams.toString()}`
      ),
    [navigate, searchParams, routes]
  );

  const goToSalesInvoices: Result["goToSalesInvoices"] = useCallback(() => {
    searchParams.delete("idsToDelete");
    navigate(`${routes.salesInvoices}?${searchParams.toString()}`);
  }, [navigate, searchParams, routes]);

  const goToSalesInvoicesSendToCustomer: Result["goToSalesInvoicesSendToCustomer"] =
    useCallback(() => {
      navigate(
        `${routes.salesInvoices}/send-to-customer?${searchParams.toString()}`
      );
    }, [navigate, routes, searchParams]);

  const goToSalesInvoicesSendToAccountant: Result["goToSalesInvoicesSendToAccountant"] =
    useCallback(() => {
      navigate(
        `${routes.salesInvoices}/send-to-accountant?${searchParams.toString()}`
      );
    }, [navigate, routes, searchParams]);

  const goToSalesInvoice: Result["goToSalesInvoice"] = useCallback(
    (id) =>
      navigate(
        `${routes.salesInvoice.replace(":id", id)}?${searchParams.toString()}`
      ),
    [navigate, searchParams, routes]
  );

  const goToQuotes: Result["goToQuotes"] = useCallback(() => {
    searchParams.delete("idsToDelete");
    navigate(`${routes.quotes}?${searchParams.toString()}`);
  }, [navigate, searchParams, routes]);

  const goToQuote: Result["goToQuote"] = useCallback(
    (id) =>
      navigate(`${routes.quote.replace(":id", id)}?${searchParams.toString()}`),
    [navigate, searchParams, routes]
  );

  const goToTimesheets: Result["goToTimesheets"] = useCallback(() => {
    searchParams.delete("idsToDelete");
    navigate(`${routes.timesheets}?${searchParams.toString()}`);
  }, [navigate, searchParams, routes]);

  const goToSubscription: Result["goToSubscription"] = useCallback(() => {
    navigate(`${routes.subscription}`);
  }, [navigate, routes]);

  const goToInvoicesFromDashboard: Result["goToInvoicesFromDashboard"] =
    useCallback(
      (path, status, from, to) => {
        if (status) {
          searchParams.set("invoicePayStatus", status);
        }

        if (from) {
          searchParams.set("from", from);
        }

        if (to) {
          searchParams.set("to", to);
        }
        navigate(`${path}?${searchParams.toString()}`);
      },
      [navigate, searchParams]
    );

  const openCreateModal: Result["openCreateModal"] = useCallback(
    () => navigate(`./create?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openPayModal: Result["openPayModal"] = useCallback(
    () => navigate(`./pay?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openDetailCreateModal: Result["openDetailCreateModal"] = useCallback(
    (subtype) => navigate(`./${subtype}/create?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openEditModal: Result["openEditModal"] = useCallback(
    (id: string) => navigate(`./edit/${id}?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openViewModal: Result["openViewModal"] = useCallback(
    (id: string) => navigate(`./view/${id}?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openDetailEditModal: Result["openDetailEditModal"] = useCallback(
    (subtype, id) =>
      navigate(`./${subtype}/edit/${id}?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openGenerateInvoiceModal: Result["openGenerateInvoiceModal"] =
    useCallback(
      (id: string) =>
        navigate(`./generate-invoice/${id}?${searchParams.toString()}`),
      [navigate, searchParams]
    );

  const openDeleteModal: Result["openDeleteModal"] = useCallback(
    (ids: string[]) => {
      ids.forEach((id) => searchParams.append("idsToDelete", id));
      navigate(`./delete?${searchParams.toString()}`);
    },
    [navigate, searchParams]
  );

  const openDetailDeleteModal: Result["openDetailDeleteModal"] = useCallback(
    (subtype, id) => {
      navigate(`./${subtype}/delete/${id}?${searchParams.toString()}`);
    },
    [navigate, searchParams]
  );

  const openConvertModal: Result["openConvertModal"] = useCallback(
    () => navigate(`./convert?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openRemindModal: Result["openRemindModal"] = useCallback(
    () => navigate(`${routes.salesInvoices}/remind?${searchParams.toString()}`),
    [navigate, routes, searchParams]
  );

  const openSendModal: Result["openSendModal"] = useCallback(
    () => navigate(`./send?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openCustomProductModal: Result["openCustomProductModal"] = useCallback(
    (index, initialValue) => {
      if (initialValue) {
        searchParams.append("initialValue", initialValue);
      }
      return navigate(
        `./create-custom-product/${index}?${searchParams.toString()}`
      );
    },
    [navigate, searchParams]
  );

  const openNoteModal: Result["openNoteModal"] = useCallback(
    (index: number) => {
      return navigate(`./note-${index}?${searchParams.toString()}`);
    },
    [navigate, searchParams]
  );

  const openUploadModal: Result["openUploadModal"] = useCallback(
    () => navigate(`./upload-csv?${searchParams.toString()}`),
    [navigate, searchParams]
  );

  const openDifferentEntityModal: Result["openDifferentEntityModal"] =
    useCallback(
      (entity, initialValue) => {
        if (initialValue) {
          searchParams.append("initialValue", initialValue);
        }
        return navigate(`./${entity}?${searchParams.toString()}`);
      },
      [navigate, searchParams]
    );

  const closeDifferentEntityModal: Result["closeDifferentEntityModal"] =
    useCallback(
      (entityParam: string) => {
        searchParams.delete("initialValue");
        const desiredPath = location.pathname.substring(
          0,
          location.pathname.lastIndexOf(`/${entityParam}`)
        );
        navigate(`${desiredPath}?${searchParams.toString()}`);
      },
      [navigate, searchParams, location]
    );

  const closeCustomProduct: Result["closeCustomProduct"] = useCallback(() => {
    searchParams.delete("initialValue");
    const desiredPath = location.pathname.substring(
      0,
      location.pathname.lastIndexOf("/create-custom-product")
    );
    navigate(`${desiredPath}?${searchParams.toString()}`);
  }, [navigate, searchParams, location]);

  const closePayModal: Result["closePayModal"] = useCallback(() => {
    searchParams.delete("initialValue");
    const desiredPath = location.pathname.substring(
      0,
      location.pathname.lastIndexOf("/pay")
    );
    navigate(`${desiredPath}?${searchParams.toString()}`);
  }, [navigate, searchParams, location]);

  const closeDetailModal: Result["closeDetailModal"] = useCallback(() => {
    navigate(`./../..?${searchParams.toString()}`);
  }, [navigate, searchParams]);

  const closeEditDetailModal: Result["closeEditDetailModal"] =
    useCallback(() => {
      navigate(`./../../..?${searchParams.toString()}`);
    }, [navigate, searchParams]);

  const setSearchParam: Result["setSearchParam"] = useCallback(
    (key: string, query: string) => {
      if (query) {
        searchParams.set(key, query);
      } else {
        searchParams.delete(key);
      }
      searchParams.delete("offset");
      navigate(`?${searchParams.toString()}`);
      event.emit("searchParamsChanged");
    },
    [navigate, searchParams]
  );

  const setPaginationParams: Result["setPaginationParams"] = useCallback(
    (offset) => {
      searchParams.set("offset", offset.toString());
      navigate(`?${searchParams.toString()}`);
      event.emit("paginationParamsChanged");
    },
    [searchParams, navigate]
  );

  const setLimitParams: Result["setLimitParams"] = useCallback(
    (limit: number) => {
      searchParams.set("limit", limit.toString());
      navigate(`?${searchParams.toString()}`);
      event.emit("paginationParamsChanged");
    },
    [searchParams, navigate]
  );

  const setSortParams: Result["setSortParams"] = useCallback(
    (sortKey) => {
      const currentKey = searchParams.get("sortKey");

      if (sortKey === currentKey) {
        const order = searchParams.get("sortOrder");

        if (order === SortOrder.DESC) {
          searchParams.delete("sortOrder");
          searchParams.delete("sortKey");
        } else {
          searchParams.set("sortOrder", SortOrder.DESC);
        }
      } else {
        searchParams.set("sortKey", sortKey);
        searchParams.set("sortOrder", SortOrder.ASC);
      }

      navigate(`?${searchParams.toString()}`);
      event.emit("sortParamsChanged");
    },
    [searchParams, navigate]
  );

  const setRouteParams: Result["setRouteParams"] = useCallback(
    (key, value) => {
      searchParams.set(key, value);
      navigate(`?${searchParams.toString()}`);
    },
    [searchParams, navigate]
  );

  return {
    routes,
    currentURL,
    redirectURL,
    action,
    goToCompanySettings,
    goToProducts,
    goToProduct,
    goToProductGroups,
    goToCustomers,
    goToCustomer,
    goToSuppliers,
    goToSupplier,
    goToPurchaseInvoices,
    goToPurchaseInvoicesSendToAccountant,
    goToPurchaseInvoice,
    goToSalesInvoices,
    goToSalesInvoice,
    goToSalesInvoicesSendToCustomer,
    goToSalesInvoicesSendToAccountant,
    goToQuotes,
    goToQuote,
    goToTimesheets,
    goToInvoicesFromDashboard,
    openCreateModal,
    openPayModal,
    openDetailCreateModal,
    openDeleteModal,
    openDetailDeleteModal,
    openEditModal,
    openDetailEditModal,
    openViewModal,
    openConvertModal,
    openSendModal,
    openCustomProductModal,
    openNoteModal,
    openGenerateInvoiceModal,
    openUploadModal,
    openDifferentEntityModal,
    closeDetailModal,
    closeEditDetailModal,
    closeDifferentEntityModal,
    closeCustomProduct,
    closePayModal,
    setSearchParam,
    setPaginationParams,
    setLimitParams,
    setSortParams,
    setRouteParams,
    goToBankAccounts,
    goToTransactions,
    openRemindModal,
    goToSubscription,
  };
};

export const useRoutesEffects = (
  routes: ReturnType<typeof useRoutesUseCases>
) => {
  useEvent("productSaved", routes.goToProducts);
  useEvent("productsDeleted", routes.goToProducts);
  useEvent("productGroupSaved", routes.goToProductGroups);
  useEvent("productGroupsDeleted", routes.goToProductGroups);
  useEvent("supplierSaved", routes.goToSupplier);
  useEvent("suppliersDeleted", routes.goToSuppliers);
  useEvent("purchaseInvoiceUploaded", routes.goToPurchaseInvoices);
  useEvent("purchaseInvoicesDeleted", routes.goToPurchaseInvoices);
  useEvent("purchaseInvoiceSaved", routes.goToPurchaseInvoices);
  useEvent("salesInvoiceSaved", routes.goToSalesInvoices);
  useEvent("salesInvoicesDeleted", routes.goToSalesInvoices);
  useEvent("quoteSaved", routes.goToQuotes);
  useEvent("quotesDeleted", routes.goToQuotes);
  useEvent("quoteConverted", routes.goToQuotes);
  useEvent("quoteSend", routes.goToQuotes);
  useEvent("customerAddressSaved", routes.goToCustomer);
  useEvent("customerAddressDeleted", routes.goToCustomer);
  useEvent("customerContactSaved", routes.goToCustomer);
  useEvent("customerContactDeleted", routes.goToCustomer);
  useEvent("customerBankAccountSaved", routes.goToCustomer);
  useEvent("customerBankAccountDeleted", routes.goToCustomer);
  useEvent("supplierAddressSaved", routes.goToSupplier);
  useEvent("supplierAddressDeleted", routes.goToSupplier);
  useEvent("supplierContactSaved", routes.goToSupplier);
  useEvent("supplierContactDeleted", routes.goToSupplier);
  useEvent("supplierBankAccountSaved", routes.goToSupplier);
  useEvent("supplierBankAccountDeleted", routes.goToSupplier);
  useEvent("customerSaved", routes.goToCustomer);
  useEvent("closeDifferentEntity", routes.closeDifferentEntityModal);
  useEvent("customersDeleted", routes.goToCustomers);
  useEvent("salesInvoiceCreated", routes.goToSalesInvoice);
  useEvent("customProductCreated", routes.closeCustomProduct);
  useEvent("timesheetSaved", routes.goToTimesheets);
  useEvent("timesheetsDeleted", routes.goToTimesheets);
  useEvent("invoiceGenerated", routes.goToTimesheets);
  useEvent("timesheetCSVUploaded", routes.goToTimesheets);
  useEvent("quoteCSVUploaded", routes.goToQuotes);
  useEvent("salesInvoiceCSVUploaded", routes.goToSalesInvoices);
  useEvent("purchaseInvoiceCSVUploaded", routes.goToPurchaseInvoices);
  useEvent("productCSVUploaded", routes.goToProducts);
  useEvent("productGroupCSVUploaded", routes.goToProductGroups);
  useEvent("customerCSVUploaded", routes.goToCustomers);
  useEvent("supplierCSVUploaded", routes.goToSuppliers);
  useEvent("reminderSend", routes.goToSalesInvoices);
};
