import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Footer } from "flowbite-react";
import classnames from "classnames";
import { useGetCustomer } from "pages/customer/queries/useGetCustomer";
import { useCustomers, useFile, useRoutes } from "domains";
import { toCurrency, Currency } from "helpers/to-currency";
import { useGetFile } from "hooks/file/queries/useGetFile";
import { AccentColor } from "hooks/templates/models/template.interface";
import { Invoice } from "models/paymentDocument/invoice/invoice.interface";
import { Link } from "react-router-dom";
import { AddressType } from "models/address/address.interface";
import { OrderTable } from "./OrderTable";

type template1Props = {
  context?: string;
  quoteDate?: string;
};
type Props = Partial<Omit<Invoice, "id" | "update">> & template1Props;

export const Template1: FC<Props> = function ({
  invoiceNumber,
  customerId,
  issueDate,
  quoteDate,
  documentLines = [],
  totalInclusiveVat = 0,
  vatPrice = 0,
  template,
  context,
  dueDate,
  reference,
  currency,
  extraInformation,
}) {
  const { t } = useTranslation();
  const { routes } = useRoutes();
  const { name, companyLogo, vatNumber, address } = useFile(useGetFile());

  const { customer } = useCustomers(useGetCustomer({ id: customerId }));

  const billingAddress = useMemo(
    () => customer?.addresses?.find((a) => a.type === AddressType.Billing),
    [customer]
  );

  const textAccentColor = useMemo(
    () =>
      classnames({
        "text-red-600": template?.accentColor === AccentColor.Red,
        "text-orange-500": template?.accentColor === AccentColor.Orange,
        "text-yellow-300": template?.accentColor === AccentColor.Yellow,
        "text-green-500": template?.accentColor === AccentColor.Green,
        "text-blue-600": template?.accentColor === AccentColor.Blue,
        "text-purple-600": template?.accentColor === AccentColor.Purple,
      }),
    [template]
  );

  return (
    <>
      <div className="flex flex-col mb-4">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <h1 className={`${textAccentColor} mb-3 text-2xl font-semibold`}>
              {invoiceNumber
                ? `${t(`invoice.title.${context ?? "invoice"}`)} ${
                    invoiceNumber
                      ? t("invoice.title.number", {
                          number: invoiceNumber,
                        })
                      : ""
                  }`
                : "#"}
            </h1>
            <p>{reference}</p>
          </div>
          <div className="flex flex-col items-end max-w-[30%]">
            {companyLogo && (
              <img
                alt="logo"
                src={companyLogo}
                className="h-12 object-contain"
              />
            )}
            {!companyLogo && (
              <h2 className="mb-2 text-xl font-semibold">{name}</h2>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="flex justify-start">
          <div className="flex flex-col text-xs">
            <strong className={classnames(textAccentColor, "uppercase")}>
              {totalInclusiveVat < 0 ? (
                <span>
                  {t(
                    context === "invoice"
                      ? "invoice.creditNoteFrom"
                      : "invoice.quoteTo"
                  )}
                </span>
              ) : (
                <span>
                  {t(
                    context === "invoice"
                      ? "invoice.billFrom"
                      : "invoice.quoteFrom"
                  )}
                </span>
              )}
            </strong>
            <p>{name}</p>
            {vatNumber && <p className="text-gray-400">{vatNumber}</p>}
            <p className="text-gray-400">
              {address?.street} {address?.number}{" "}
              {address?.bus ? `/ ${address?.bus}` : ""}
            </p>
            <p className="text-gray-400">
              {address?.zipCode} {address?.city}
            </p>
            <p className="text-gray-400">{t(`country.${address?.country}`)}</p>
          </div>
        </div>
        <div className="flex flex-col text-xs">
          <strong className={classnames(textAccentColor, "uppercase")}>
            {totalInclusiveVat < 0 ? (
              <span>
                {t(
                  context === "invoice"
                    ? "invoice.creditNoteTo"
                    : "invoice.quoteTo"
                )}
              </span>
            ) : (
              <span>
                {t(
                  context === "invoice" ? "invoice.billTo" : "invoice.quoteTo"
                )}
              </span>
            )}
          </strong>
          {customer && (
            <div>
              <Link
                to={routes.customer.replace(":id", customer?.id)}
                className="hover:underline"
              >
                {customer?.name}
              </Link>
            </div>
          )}
          {customer?.vatNumber && (
            <p className="text-gray-400">{customer.vatNumber}</p>
          )}
          {billingAddress && (
            <>
              <p className="text-gray-400">
                {billingAddress?.street} {billingAddress?.number}{" "}
                {billingAddress?.bus ? `/ ${billingAddress?.bus}` : ""}
              </p>
              <p className="text-gray-400">
                {billingAddress?.zipCode} {billingAddress?.city}
              </p>
              <p className="text-gray-400">
                {" "}
                {t(`country.${billingAddress?.country}`)}
              </p>
            </>
          )}
        </div>
        <div className="flex flex-col text-xs">
          <div className="flex flex-col items-end">
            <span className="font-medium">
              {context === "invoice"
                ? t("invoice.invoiceDate")
                : t("quoteTable.title.quoteDate")}
              :{" "}
            </span>
            <span className="text-gray-400">
              {issueDate || quoteDate || ""}
            </span>
          </div>
          <div className="flex flex-col items-end mt-2">
            <span className="font-medium">{t("invoice.expiryDate")}: </span>
            <span className="text-gray-400">{dueDate}</span>
          </div>
        </div>
      </div>
      <OrderTable
        rows={documentLines}
        className="w-full mt-8"
        theme={{ root: { wrapper: "static" } }}
        accentColor={template?.accentColor}
        currency={currency}
      />
      <Footer.Divider className="dark:!border-gray-200" />
      <div className="flex flex-col w-full items-end">
        <div className="flex justify-between w-48">
          <span className="text-gray-400">{t("invoice.subtotal")}</span>
          <span>
            {toCurrency(
              totalInclusiveVat - vatPrice,
              currency || Currency.Euro
            )}
          </span>
        </div>

        <div className="flex justify-between w-48">
          <span className="text-gray-400">{t("invoice.vat")}</span>
          <span>{toCurrency(vatPrice, currency || Currency.Euro)}</span>
        </div>

        <div className="flex justify-between w-48">
          <strong className={textAccentColor}>{t("invoice.total")}</strong>
          <strong className={textAccentColor}>
            {toCurrency(totalInclusiveVat, currency || Currency.Euro)}
          </strong>
        </div>
      </div>
      <Footer.Divider className="dark:!border-gray-200" />
      <span className="text-xs font-medium">{extraInformation}</span>
    </>
  );
};
