import { FC } from "react";

interface Props {
  url: string;
}

export const Logo: FC<Props> = function ({ url }) {
  return (
    <div className="my-6 flex items-center gap-x-1 lg:my-0">
      <img alt="logo" src={url} className="mr-3 h-12" />
    </div>
  );
};
