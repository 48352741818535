import {
  PaymentDocumentBase,
  InvoicePayType,
} from "../paymentDocument.interface";

export enum InvoicePayStatus {
  Paid = "Paid",
  Due = "DUE",
  OverDue = "OVERDUE",
  Processing = "PAYMENT_PROCESSING",
}

export enum FinancialDocumentType {
  PurchaseInvoice = "PURCHASE_INVOICE",
  CreditNote = "PURCHASE_CREDIT_NOTE",
}

export enum QuoteStatus {
  Saved = "SAVED",
  Sent = "SENT",
  ConvertedToInvoice = "CONVERTED_TO_INVOICE",
}

export enum InvoiceStatus {
  SendToCustomer = "SENT_TO_CUSTOMER",
  SendToAccountant = "SENT_TO_ACCOUNTANT",
  Saved = "SAVED",
}

export interface Reminder {
  id: number;
  reminderType: string;
  reminderSentType: string;
  timeSent: string;
  sender: string;
}

export interface Invoice extends PaymentDocumentBase {
  invoicePayStatus?: InvoicePayStatus;
  invoiceStatus?: InvoiceStatus;
  quoteStatus?: QuoteStatus;
  invoicePayType?: InvoicePayType;
  payDate?: string;
  freeCommunication?: string;
  structuredCommunication?: string;
  reminders?: Reminder[];
}
