import { FC } from "react";

import { Customer } from "hooks/customers/models/customer.interface";
import { InvoicePreview } from "components/invoice/InvoicePreview";
import { Product } from "hooks/products/models/product.interface";
import { SkeletonTemplate } from "components/skeletons/SkeletonTemplate";
import { Template } from "hooks/templates/models/template.interface";
import { Template1 } from "components/invoice/templates/Template1";
import { useInvoiceFormValues } from "components/invoice/hooks/useInvoiceFormValues";
import { SalesInvoice } from "hooks/salesInvoices/models/salesInvoice.interface";
import { Quote } from "hooks/quotes/models/quote.interface";
import { convertDateFormat } from "helpers/convert-date-format";

interface Props {
  isLoading: boolean;
  customers: Customer[];
  products: Product[];
  template?: Omit<Template, "delete">;
  data?: SalesInvoice | Quote;
  context: "invoice" | "quote";
}

export const DocumentPreview: FC<Props> = function ({
  isLoading,
  data,
  customers,
  products,
  template,
  context,
}) {
  const {
    customerId,
    issueDate,
    quoteDate,
    dueDate,
    total,
    vat,
    reference,
    currency,
    selectedAddress,
    selectedTemplate,
    selectedProducts,
    extraInformation,
  } = useInvoiceFormValues({ customers, products });

  return (
    <InvoicePreview>
      {isLoading ? (
        <SkeletonTemplate />
      ) : (
        <Template1
          customerId={customerId || data?.customerId}
          billingAddress={selectedAddress || data?.billingAddress}
          documentLines={selectedProducts}
          issueDate={convertDateFormat(issueDate)}
          dueDate={convertDateFormat(dueDate)}
          quoteDate={convertDateFormat(quoteDate)}
          invoiceNumber={data?.invoiceNumber}
          template={data?.template ?? template ?? selectedTemplate}
          totalInclusiveVat={
            total && vat ? total + vat : data?.totalInclusiveVat
          }
          vatPrice={vat || data?.vatPrice}
          reference={reference || data?.reference}
          currency={currency || data?.currency}
          context={context}
          extraInformation={extraInformation}
        />
      )}
    </InvoicePreview>
  );
};
